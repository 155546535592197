import type { MTableColumn } from '@mm-frontend/mithril-ui-kit';
import type { RouteRecordRaw } from 'vue-router';

import { readableLogisticPointType } from '@/entities/logistic-points';
import type {
  GetLogisticPointsParams,
  LogisticPoint,
} from '@/shared/api/generated-api/logistic-points/data-contracts.ts';
import { RouteName } from '@/shared/config/router';
import { useRouteLoadingStore } from '@/shared/config/stores/route-loading-store.ts';

export const route: RouteRecordRaw = {
  path: 'logistic-points',
  name: RouteName.LOGISTIC_POINTS,
  component: () => {
    useRouteLoadingStore().setLoading(true);
    return import('./ui/LogisticPoints.vue');
  },
};

export const tableColumns: MTableColumn<LogisticPoint>[] = [
  {
    label: 'Город пункта',
    value: 'city',
    customRender: (data) => data.address.city || '',
  },
  {
    label: 'Код пункта',
    value: 'shortName',
  },
  {
    label: 'Тип пункта',
    value: 'type',
    customRender: (data) => readableLogisticPointType(data.type),
  },
  {
    label: 'Адрес логистического пункта',
    value: 'fullAddress',
  },
  {
    label: 'Статус',
    value: 'status',
  },
];

export const defaultFilter: GetLogisticPointsParams = {
  city: '',
  code: '',
  type: [],
  status: [],
};

export const LOGISTIC_POINTS_PER_PAGE_SIZE = 50;
