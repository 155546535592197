import { MithrilUiKit } from '@mm-frontend/mithril-ui-kit';
import type { App } from 'vue';

export const withUiKit = (app: App): void => {
  app.use(MithrilUiKit, {
    floatingVue: {
      themes: {
        'send-transportation-order': {
          $extend: 'confirm',
        },
        'add-utilization': {
          $extend: 'confirm',
        },
        'close-confirmation': {
          $extend: 'confirm',
        },
        'cancel-approved-transportation-order-edit': {
          $extend: 'confirm',
        },
      },
    },
    inputFacade: {
      tokens: {
        R: { pattern: /[\dA-CEHKMOPTXYa-cekm-ptxy]/, transform: (v: string) => v.toLocaleUpperCase() },
        N: { pattern: /[\da-cehkmoptxyавекмнорстух]/i },
        F: {
          pattern: /^[\d\s!"#$%&'()*+,./:;<=>?@[\\\]^_`{|}~ЁА-я-]+$/,
          transform: (v: string) => v.toLocaleUpperCase(),
        },
      },
    },
  });
};
