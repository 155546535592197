import type { ApiMode, SlideItem, UserWithTokens } from '@mm-frontend/ops-auth';

import { isDevelopmentApiUrl } from '@/shared/env';

export const slides: SlideItem[] = [
  {
    image: 'img/slider/slide1',
    title: '',
    date: '',
  },
];

export const currentMode: ApiMode = isDevelopmentApiUrl ? 'dev' : 'prod';

export type TmsUserWithTokens = UserWithTokens;
export type TmsUserInfo = TmsUserWithTokens['userInfo'];
