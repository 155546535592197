/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  Carrier,
  CarrierAggregate,
  GenericBadRequestError,
  GenericError,
  GetCarriersParams,
  HttpMethod,
  Pagination,
} from './data-contracts';
import { HttpClient, RequestParams } from './http-client';

export const CarriersRoutesRegExp = {
  getCarriers: {
    regExp: '^\\/api\\/v1\\/carriers$',
    method: HttpMethod.GET,
  },
  getCarrierById: {
    regExp: '^\\/api\\/v1\\/carriers\\/\\d+$',
    method: HttpMethod.GET,
  },
};

export class Carriers<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * @description Получить список перевозчиков
   *
   * @tags Carriers
   * @name GetCarriers
   * @summary Получение списка перевозчиков
   * @request GET:/api/v1/carriers
   */
  getCarriers = (query: GetCarriersParams, params: RequestParams = {}) =>
    this.request<
      {
        list: Carrier[];
        /** Постраничная навигация */
        pagination: Pagination;
      },
      GenericBadRequestError | GenericError
    >({
      path: `/api/v1/carriers`,
      method: 'GET',
      query: query,
      ...params,
    });
  /**
   * @description Получить перевозчика по id
   *
   * @tags Carriers
   * @name GetCarrierById
   * @summary Получение перевозчика по id
   * @request GET:/api/v1/carriers/{carrier_id}
   */
  getCarrierById = (carrierId: string, params: RequestParams = {}) =>
    this.request<CarrierAggregate, GenericBadRequestError | GenericError>({
      path: `/api/v1/carriers/${carrierId}`,
      method: 'GET',
      ...params,
    });
}
