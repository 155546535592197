import { notificationManager, type OpenModalOptions } from '@mm-frontend/mithril-ui-kit';
import type { AxiosError } from 'axios';

import type {
  GenericBadRequestError,
  GenericError,
  ValidationError,
} from '@/shared/api/generated-api/drivers/data-contracts';
import type {
  GenericUnprocessableEntityError as FleetsGenericBadRequestError,
  GenericError as FleetsGenericError,
} from '@/shared/api/generated-api/fleets/data-contracts';
import type {
  GenericBadRequestError as TransportationOrdersGenericBadRequestError,
  GenericError as TransportationOrdersGenericError,
} from '@/shared/api/generated-api/transportation-orders/data-contracts';
import { changePropertiesToCamelCase } from '@/shared/api/generated-api/transportation-orders/http-client';

type TMSError =
  | GenericBadRequestError
  | GenericError
  | FleetsGenericBadRequestError
  | FleetsGenericError
  | TransportationOrdersGenericError
  | TransportationOrdersGenericBadRequestError;

/**
 * Проверить, является ли ошибка TMS-ошибкой на основе наличия описания ошибки.
 *
 * @param error - Объект ошибки для проверки
 * @return Является ли ошибка TMS-ошибкой
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const checkTMSError = (error: any): error is AxiosError<TMSError> => {
  if (!error.response?.data || typeof error.response.data !== 'object') return false;

  // Проверяем что это TMS  ошибка по наличию сообщения
  return 'detail' in error.response.data;
};

/**
 * Преобразует объект TMSError в объект OpenModalOptions для уведомлений.
 *
 * @param tmsError - Преобразуемый объект TMSError
 * @return Преобразованный объект OpenModalOptions для уведомлений
 */

export const convertTMSErrorToNotification = (tmsError?: TMSError): OpenModalOptions => {
  if (typeof tmsError !== 'object') return { title: 'Неизвестная ошибка' };

  let blockquote: string[] = [];
  const convertedTmsError: TMSError = changePropertiesToCamelCase(tmsError);

  if ('validationErrors' in convertedTmsError) {
    blockquote = convertedTmsError.validationErrors.map(
      (validationError: ValidationError) => `${validationError.fieldName}: ${validationError.message}`,
    );
  }

  return {
    title: tmsError.title,
    description: tmsError.detail,
    blockquote,
  };
};

/**
 * Обрабатывает общие ошибки, проверяя, являются ли они типом TMS, и если да, открывает уведомление toast с соответствующим сообщением об ошибке.
 * (in future) Если ошибка является экземпляром ZodError, открывается модальное уведомление с предопределенным заголовком, описанием и массивом сообщений об ошибках из объекта ZodError.
 *
 * @param {any} error - Ошибка, которую нужно обработать.
 * @param {boolean} ignoreNotification -Нужно ли игнорировать ошибку, не показывая нотификацию
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const generalErrorHandler = (error: any, ignoreNotification: boolean = false): void => {
  if (['RefreshError', 'CanceledError'].includes(error.name) || ignoreNotification) {
    return;
  }

  const notificationOptions = error.response?.data
    ? convertTMSErrorToNotification(error.response.data)
    : { title: 'Неизвестная ошибка' };
  notificationManager.openModal('error', notificationOptions);
};
