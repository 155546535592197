/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  DeleteDriverLicensesByIdData,
  DriverLicense,
  GenericBadRequestError,
  GenericError,
  HttpMethod,
} from './data-contracts';
import { HttpClient, RequestParams } from './http-client';

export const DriversLicensesRoutesRegExp = {
  postDriverLicensesByDriverId: {
    regExp: '^\\/api\\/v1\\/drivers\\/\\d+\\/drivers-licenses$',
    method: HttpMethod.POST,
  },
  deleteDriverLicensesById: {
    regExp: '^\\/api\\/v1\\/drivers\\/\\d+$',
    method: HttpMethod.DELETE,
  },
};

export class DriversLicenses<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * @description Ручка создает водительские удостоверения водителя по его ID
   *
   * @tags Driver's Licenses
   * @name PostDriverLicensesByDriverId
   * @summary Создание водительского удостоверения
   * @request POST:/api/v1/drivers/{driver_id}/drivers-licenses
   */
  postDriverLicensesByDriverId = (driverId: string, data: DriverLicense, params: RequestParams = {}) =>
    this.request<DriverLicense, GenericBadRequestError | GenericError>({
      path: `/api/v1/drivers/${driverId}/drivers-licenses`,
      method: 'POST',
      body: data,
      ...params,
    });
  /**
   * @description Ручка удаляет водительское удостоверение водителя по его ID
   *
   * @tags Driver's Licenses
   * @name DeleteDriverLicensesById
   * @summary Удаление водительского удостоверения
   * @request DELETE:/api/v1/drivers/{driver_id}/drivers-licenses/{drivers_license_id}
   */
  deleteDriverLicensesById = (driverId: string, driversLicenseId: string, params: RequestParams = {}) =>
    this.request<DeleteDriverLicensesByIdData, GenericBadRequestError | GenericError>({
      path: `/api/v1/drivers/${driverId}/drivers-licenses/${driversLicenseId}`,
      method: 'DELETE',
      ...params,
    });
}
