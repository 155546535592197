<template>
  <div :class="['point', { 'point-error': props.fetchingError }]">
    <div class="point-content">
      <div class="actions">
        <template v-if="props.modelValue.position !== 1">
          <m-icon
            size="20"
            name="re-order-dots"
            color="tertiary"
          />
          <m-checkbox
            class="checkbox"
            :model-value="props.checkbox"
            @update:model-value="emit('update:checkbox', $event)"
          />
          <m-tag
            size="small"
            class="point-number"
            color="violet"
          >
            {{ props.modelValue.position - 1 }}
          </m-tag>
        </template>
        <template v-else>
          <m-icon
            size="20"
            name="home"
            variant="filled"
            color="info"
          />
        </template>
      </div>
      <div class="point-select">
        <logistic-points-select
          v-model="selectedLogisticPoint"
          class="filter-input"
          size="small"
          placeholder="Укажите пункт"
          :is-clearable="false"
          :errors="props.errors.logisticPointId"
        />
        <div class="point-select-info">
          <template v-if="props.modelValue.position !== 1">
            <m-icon
              size="16"
              name="clock"
              color="secondary"
            />
            <m-typography> {{ `${timeFrom}${timeTo ? '-' + timeTo : ''}` }}</m-typography>
          </template>
          <m-typography
            v-else
            variant="CaptionMRegular"
            color="tertiary"
          >
            {{ point.logisticPoint?.address.fullAddress }}
          </m-typography>
        </div>
      </div>
      <div class="transportation-time">
        <div :class="['time', 'departure', { 'is-readonly': isReadonlyTime }]">
          <m-icon
            size="16"
            name="arrow-import"
            color="secondary"
          />
          <template v-if="isReadonlyTime">
            <m-typography
              :class="['time-text', { 'skeleton-mask': props.isFetching }]"
              component="span"
            >
              {{ formatDate(removeUTC(props.modelValue.planArriveAt), 'shortTime', timePlaceholder) }}
            </m-typography>
            <m-typography
              v-if="!props.isFetching"
              component="span"
              color="tertiary"
            >
              &nbsp;/
              {{ formatDate(removeUTC(props.modelValue.planArriveAt), 'dateNumeric', datePlaceholder) }}
            </m-typography>
          </template>
          <template v-else>
            <m-input
              v-model="arrivalDate.time"
              size="small"
              type="time"
              placeholder="Время"
              :clearable="false"
              :errors="planArriveAtValidationErrors"
              @blur="onUpdateArrivalDate"
            />
            <input-datepicker
              v-model="arrivalDate.date"
              :min-date="todayDate"
              :errors="planArriveAtValidationErrors"
              @update:model-value="onUpdateArrivalDate"
            />
          </template>
        </div>
        <div :class="['time', 'arrival', { 'is-readonly': isIntracityTransition }]">
          <m-icon
            size="16"
            name="arrow-export-ltr"
            color="secondary"
          />
          <template v-if="isIntracityTransition">
            <m-typography
              :class="['time-text', { 'skeleton-mask': props.isFetching && props.modelValue.position !== 1 }]"
              component="span"
            >
              {{ formatDate(removeUTC(props.modelValue.planDepartAt), 'shortTime', timePlaceholder) }}
            </m-typography>
            <m-typography
              v-if="!props.isFetching || props.modelValue.position === 1"
              component="span"
              color="tertiary"
            >
              &nbsp;/
              {{ formatDate(removeUTC(props.modelValue.planDepartAt), 'dateNumeric', datePlaceholder) }}
            </m-typography>
          </template>
          <template v-else>
            <m-input
              v-model="departureDate.time"
              size="small"
              type="time"
              placeholder="Время"
              :clearable="false"
              :errors="props.errors.planDepartAt"
              @blur="onUpdateDepartureDate"
            />
            <input-datepicker
              v-model="departureDate.date"
              :min-date="todayDate"
              :errors="props.errors.planDepartAt"
              @update:model-value="onUpdateDepartureDate"
            />
          </template>
        </div>
      </div>
    </div>
    <m-typography
      v-for="error in errorsToShow"
      :key="error"
      class="error-block"
      variant="CaptionMMedium"
      color="negative"
    >
      {{ error }}
    </m-typography>
  </div>
</template>

<script setup lang="ts">
import { formatDate, MCheckbox, MIcon, MInput, MTag, MTypography } from '@mm-frontend/mithril-ui-kit';
import { computed, ref, watch } from 'vue';

import {
  logisticPointScheduleDayOfWeekMap,
  LogisticPointsSelect,
  readableDayOfWeekShort,
} from '@/entities/logistic-points';
import { TransportationType } from '@/shared/api/generated-api/transportation-orders/data-contracts';
import type { SchedulePoint } from '@/shared/api/generated-api/transportation-orders/data-contracts';
import type { ValidationErrors } from '@/shared/config/validation';
import { removeUTC } from '@/shared/lib/utils';
import { InputDatepicker } from '@/shared/ui/input-datepicker';

import { datePlaceholder, getDefaultPoint, timePlaceholder } from '../config';
import { addShipmentToTime, formatDateToIso, formatISODateToTransitDate } from '../lib';
import type { TransitDate } from '../types';

const props = withDefaults(
  defineProps<{
    modelValue?: SchedulePoint;
    checkbox?: boolean;
    fetchingError?: boolean;
    errors?: ValidationErrors;
    warnings?: ValidationErrors;
    orderType?: TransportationType;
    isFetching?: boolean;
    cargoVolume?: number;
    isManualMode?: boolean;
  }>(),
  {
    modelValue: getDefaultPoint,
    errors: () => ({}),
    warnings: () => ({}),
    orderType: TransportationType.TRANSPORTATION_TYPE_INTRACITY_TRANSPORTATION,
    cargoVolume: undefined,
  },
);

const emit = defineEmits<{
  'update:modelValue': [value: SchedulePoint];
  'update:checkbox': [value: boolean];
}>();

const point = computed<SchedulePoint>({
  get() {
    return props.modelValue;
  },
  set(value) {
    emit('update:modelValue', value);
  },
});

const todayDate = new Date();
const arrivalDate = ref<TransitDate>(formatISODateToTransitDate(removeUTC(point.value.planArriveAt)));

const departureDate = ref<TransitDate>(formatISODateToTransitDate(removeUTC(point.value.planDepartAt)));

const isIntracityTransition = computed(() => {
  return props.orderType === TransportationType.TRANSPORTATION_TYPE_INTRACITY_TRANSPORTATION;
});

const isReadonlyTime = computed(() => {
  return props.modelValue.position !== 1 && isIntracityTransition.value && !props.isManualMode;
});

const selectedLogisticPoint = computed({
  get() {
    return props.modelValue.logisticPoint;
  },
  set(value) {
    emit('update:modelValue', {
      ...props.modelValue,
      logisticPoint: value,
      logisticPointId: value?.id || '',
    });
  },
});

// Поскольку проблемы с опережающими временами вернут текст, а нам его не надо показывать под маленькими инпутом, то
// отдаем пустой массив, чтобы подчеркнуть инпуты красным, а текст выведем внизу карточки
// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
const planArriveAtValidationErrors = computed(() => (props.errors['planArriveAt']?.length ? [''] : undefined));

const errorsToShow = computed(() => {
  let errorsExist = false;
  const errors = Object.keys(props.errors).reduce((acc: string[], key: string) => {
    if (props.errors[key].length) {
      errorsExist = true;
    }

    acc.push(...props.errors[key].filter(Boolean));
    return acc;
  }, []);

  // Показываем ворнинги только если нет критических ошибок
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (errorsExist) {
    return errors;
  }

  const warnings = Object.keys(props.warnings).reduce((acc: string[], key: string) => {
    acc.push(...props.warnings[key].filter(Boolean));
    return acc;
  }, []);

  return [...errors, ...warnings];
});

const getShipmentTime = (arrivalDate: string): TransitDate | undefined => {
  if (!props.cargoVolume) return;
  const convertedDepartureDate = addShipmentToTime(
    arrivalDate,
    props.cargoVolume,
    props.modelValue.position === 1 || !isIntracityTransition.value,
  );
  return { time: convertedDepartureDate.time, date: convertedDepartureDate.date };
};

const onUpdateArrivalDate = (): void => {
  const arrivalDateIso = formatDateToIso(arrivalDate.value);
  const newPoint = { ...point.value, planArriveAt: arrivalDateIso };

  const departureWithShipment = getShipmentTime(arrivalDateIso);
  if (departureWithShipment) {
    departureDate.value = departureWithShipment;
    const departureDateIso = formatDateToIso(departureWithShipment);
    if (departureDateIso) newPoint.planDepartAt = departureDateIso;
  }

  point.value = newPoint;
};

const onUpdateDepartureDate = (): void => {
  point.value = { ...point.value, planDepartAt: formatDateToIso(departureDate.value) };
};

watch(
  () => props.cargoVolume,
  () => {
    const departureWithShipment = getShipmentTime(formatDateToIso(arrivalDate.value));
    const departureDateIso = departureWithShipment ? formatDateToIso(departureWithShipment) : undefined;
    if (departureWithShipment) point.value = { ...point.value, planDepartAt: departureDateIso };
  },
);

const timeFrom = computed<string>(() => {
  if (!(selectedLogisticPoint.value?.schedule && arrivalDate.value.date)) {
    return timePlaceholder;
  }

  const arrivalDateDay = logisticPointScheduleDayOfWeekMap[arrivalDate.value.date.getDay()];
  const arrivalDateSchedule = selectedLogisticPoint.value.schedule.find((s) => s.dayOfWeek === arrivalDateDay);
  return arrivalDateSchedule?.openedAt || `${readableDayOfWeekShort(arrivalDateDay)}: закрыто`;
});

const timeTo = computed<string | undefined>(() => {
  if (!(selectedLogisticPoint.value?.schedule && arrivalDate.value.date)) {
    return timePlaceholder;
  }

  const arrivalDateDay = logisticPointScheduleDayOfWeekMap[arrivalDate.value.date.getDay()];
  const arrivalDateSchedule = selectedLogisticPoint.value.schedule.find((s) => s.dayOfWeek === arrivalDateDay);
  return arrivalDateSchedule?.closedAt;
});

watch(
  () => point.value.planArriveAt,
  () => {
    if (point.value.position === 1 || !isIntracityTransition.value || props.isManualMode) return;
    arrivalDate.value = formatISODateToTransitDate(removeUTC(point.value.planArriveAt));
  },
);

watch(
  () => point.value.planDepartAt,
  () => {
    if (point.value.position === 1 || !isIntracityTransition.value || props.isManualMode) return;
    departureDate.value = formatISODateToTransitDate(removeUTC(point.value.planDepartAt));
  },
);
</script>

<style lang="scss" scoped>
.time.is-readonly {
  grid-template-columns: 16px auto auto;
  justify-content: flex-start;
  gap: 6px;
}

.time-text {
  margin-left: 6px;
}

.point {
  border-radius: 16px;
  background-color: var(--background-primary);
  box-shadow:
    0 0 1px 0 rgba(31, 32, 38, 0.07),
    0 0 4px 0 rgba(31, 32, 38, 0.07);
  padding: 8px 8px 8px 4px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  &.point-error {
    outline: 1px solid var(--border-accented);
  }

  & :deep(input[type='time']::-webkit-calendar-picker-indicator) {
    background: none;
    display: none;
  }
}

.point-content {
  display: flex;
  align-items: flex-start;
}

.time {
  align-items: center;
  display: grid;
  grid-template-columns: 16px 64px 106px;
  gap: 2px;
  :deep(.outside) {
    margin-top: 0;
  }
}

.transportation-time {
  display: grid;
  gap: 4px;
  width: 190px;
}

.point-select {
  margin: 0 6px 0 10px;
  width: 152px;
}

.point-select-info {
  margin-top: 2px;
  display: flex;
  gap: 4px;
}

.actions {
  display: flex;
  align-items: center;
  padding: 8px 0;
}

.checkbox {
  margin-left: 7px;
  margin-right: 3px;
}

.time :deep(.m-input .input-wrapper) {
  gap: 2px;
}

.error-block {
  min-width: 348px;
  margin-top: 4px;
}
</style>
