export enum RouteName {
  LOGIN = 'Login',
  DRIVERS = 'Drivers',
  VEHICLES = 'Vehicles',
  MODELS = 'Models',
  RESTORE = 'restore',
  CARRIERS = 'Carriers',
  LOGISTIC_POINTS = 'LogisticPoints',
  HOME = 'home',
  TRANSPORTATION_ORDERS = 'transportation-orders',
  TRANSPORTATION_ORDER_ADD = 'transportation-order-add',
  TRANSPORTATION_ORDER = 'transportation-order',
  MONITORING = 'monitoring',
  NOT_FOUND = 'not-found',
}

export enum LayoutType {
  LOGIN = 'login',
  DEFAULT = 'default',
}
