import { defineStore } from 'pinia';

export type State = {
  isLoading: boolean;
};

export const useRouteLoadingStore = defineStore('route-loading', {
  state: (): State => ({
    isLoading: false,
  }),
  actions: {
    setLoading(isLoading: boolean): void {
      this.isLoading = isLoading;
    },
  },
});
