import { helpers, minLength, required } from '@vuelidate/validators';

import { createMinLengthMessage, REGEX_MESSAGE, REQUIRED_MESSAGE } from '@/shared/config/validation';

export const validationRules = {
  series: {
    required: helpers.withMessage(REQUIRED_MESSAGE, required),
    seriesRegex: helpers.withMessage(REGEX_MESSAGE, helpers.regex(/^\d{2}\s([А-я]{2}|\d{2})$/)),
  },
  number: {
    required: helpers.withMessage(REQUIRED_MESSAGE, required),
    minLength: helpers.withMessage(createMinLengthMessage(6), minLength(6)),
  },
  issuedAt: {
    required: helpers.withMessage(REQUIRED_MESSAGE, required),
  },
};
