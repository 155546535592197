/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { GenericBadRequestError, GenericError, HttpMethod } from './data-contracts';
import { HttpClient, RequestParams } from './http-client';

export const StaticRoutesRegExp = {
  downloadFile: {
    regExp: '^\\/api\\/v1\\/static\\/\\d+$',
    method: HttpMethod.GET,
  },
};

export class Static<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * @description Скачивание файла по имени
   *
   * @tags Static
   * @name DownloadFile
   * @summary Скачивание файла
   * @request GET:/api/v1/static/{filename}
   */
  downloadFile = (filename: string, params: RequestParams = {}) =>
    this.request<File, GenericBadRequestError | GenericError>({
      path: `/api/v1/static/${filename}`,
      method: 'GET',
      ...params,
    });
}
