/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  CreateOutsourceDriver,
  CreateStaffDriver,
  Driver,
  DriverAggregate,
  GenericBadRequestError,
  GenericError,
  GetDriversParams,
  HttpMethod,
  Pagination,
  UpdateOutsourceDriver,
  UpdateStaffDriver,
} from './data-contracts';
import { HttpClient, RequestParams } from './http-client';

export const DriversRoutesRegExp = {
  getDriverById: {
    regExp: '^\\/api\\/v1\\/drivers\\/\\d+$',
    method: HttpMethod.GET,
  },
  updateStaffDriver: {
    regExp: '^\\/api\\/v1\\/drivers\\/staff\\/\\d+$',
    method: HttpMethod.PUT,
  },
  createStaffDriver: {
    regExp: '^\\/api\\/v1\\/drivers\\/staff$',
    method: HttpMethod.POST,
  },
  updateOutsourceDriver: {
    regExp: '^\\/api\\/v1\\/drivers\\/outsource\\/\\d+$',
    method: HttpMethod.PUT,
  },
  createOutsourceDriver: {
    regExp: '^\\/api\\/v1\\/drivers\\/outsource$',
    method: HttpMethod.POST,
  },
  getDrivers: {
    regExp: '^\\/api\\/v1\\/drivers$',
    method: HttpMethod.GET,
  },
};

export class Drivers<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * @description Ручка возвращает данные водителя по его ID
   *
   * @tags Drivers
   * @name GetDriverById
   * @summary Получение данных водителя
   * @request GET:/api/v1/drivers/{driver_id}
   */
  getDriverById = (driverId: string, params: RequestParams = {}) =>
    this.request<DriverAggregate, GenericBadRequestError | GenericError>({
      path: `/api/v1/drivers/${driverId}`,
      method: 'GET',
      ...params,
    });
  /**
   * @description Изменение собственного водителя
   *
   * @tags Drivers
   * @name UpdateStaffDriver
   * @summary Изменение собственного водителя
   * @request PUT:/api/v1/drivers/staff/{driver_id}
   */
  updateStaffDriver = (driverId: string, data: UpdateStaffDriver, params: RequestParams = {}) =>
    this.request<DriverAggregate, GenericBadRequestError | GenericError>({
      path: `/api/v1/drivers/staff/${driverId}`,
      method: 'PUT',
      body: data,
      ...params,
    });
  /**
   * @description Создание собственного водителя
   *
   * @tags Drivers
   * @name CreateStaffDriver
   * @summary Создание собственного водителя
   * @request POST:/api/v1/drivers/staff
   */
  createStaffDriver = (data: CreateStaffDriver, params: RequestParams = {}) =>
    this.request<DriverAggregate, GenericBadRequestError | GenericError>({
      path: `/api/v1/drivers/staff`,
      method: 'POST',
      body: data,
      ...params,
    });
  /**
   * @description Изменение привлеченного водителя
   *
   * @tags Drivers
   * @name UpdateOutsourceDriver
   * @summary Изменение привлеченного водителя
   * @request PUT:/api/v1/drivers/outsource/{driver_id}
   */
  updateOutsourceDriver = (driverId: string, data: UpdateOutsourceDriver, params: RequestParams = {}) =>
    this.request<DriverAggregate, GenericBadRequestError | GenericError>({
      path: `/api/v1/drivers/outsource/${driverId}`,
      method: 'PUT',
      body: data,
      ...params,
    });
  /**
   * @description Создание привлеченного водителя
   *
   * @tags Drivers
   * @name CreateOutsourceDriver
   * @summary Создание привлеченного водителя
   * @request POST:/api/v1/drivers/outsource
   */
  createOutsourceDriver = (data: CreateOutsourceDriver, params: RequestParams = {}) =>
    this.request<DriverAggregate, GenericBadRequestError | GenericError>({
      path: `/api/v1/drivers/outsource`,
      method: 'POST',
      body: data,
      ...params,
    });
  /**
   * @description Получить список водителей
   *
   * @tags Drivers
   * @name GetDrivers
   * @summary Получение списка водителей
   * @request GET:/api/v1/drivers
   */
  getDrivers = (query: GetDriversParams, params: RequestParams = {}) =>
    this.request<
      {
        list: Driver[];
        /** Постраничная навигация */
        pagination: Pagination;
      },
      GenericBadRequestError | GenericError
    >({
      path: `/api/v1/drivers`,
      method: 'GET',
      query: query,
      ...params,
    });
}
