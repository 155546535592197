import type { MOptionItem, MStatusDotColor } from '@mm-frontend/mithril-ui-kit';
import { formatPhone, readableEnum } from '@mm-frontend/mithril-ui-kit';

import type { Driver } from '@/shared/api/generated-api/transportation-orders/data-contracts';
import { TripEventEventTypeEnum, TripStatus } from '@/shared/api/generated-api/transportation-orders/data-contracts';
import { formatName } from '@/shared/lib/format';

import type { TripDriverEdit, TripVehicleEdit } from './types';

export const readableTripStatus = readableEnum<TripStatus>({
  [TripStatus.TRIP_STATUS_CREATED]: 'Создан',
  [TripStatus.TRIP_STATUS_CANCELED]: 'Отменен',
  [TripStatus.TRIP_STATUS_ON_THE_WAY]: 'В пути',
  [TripStatus.TRIP_STATUS_WAITING_FOR_INPUT]: 'Ожидает данных',
  [TripStatus.TRIP_STATUS_WAITING_FOR_LOADING]: 'Ожидает погрузку',
  [TripStatus.TRIP_STATUS_AT_LOG_POINT]: 'На лог пункте',
  [TripStatus.TRIP_STATUS_COMPLETED]: 'Завершен',
});

export const readableTripEvent = readableEnum<TripEventEventTypeEnum>({
  [TripEventEventTypeEnum.TRIP_EVENT_TYPE_ARRIVAL]: 'Прибытие',
  [TripEventEventTypeEnum.TRIP_EVENT_TYPE_DEPARTURE]: 'Отправление',
});

export const colorFromTripStatus = readableEnum<TripStatus, MStatusDotColor>({
  [TripStatus.TRIP_STATUS_CREATED]: 'orange',
  [TripStatus.TRIP_STATUS_CANCELED]: 'red',
  [TripStatus.TRIP_STATUS_ON_THE_WAY]: 'persian',
  [TripStatus.TRIP_STATUS_WAITING_FOR_INPUT]: 'orange',
  [TripStatus.TRIP_STATUS_WAITING_FOR_LOADING]: 'persian',
  [TripStatus.TRIP_STATUS_AT_LOG_POINT]: 'persian',
  [TripStatus.TRIP_STATUS_COMPLETED]: 'green',
});

export const tripStatusOptions: MOptionItem[] = Object.values(TripStatus).map((tripStatus: TripStatus) => ({
  title: readableTripStatus(tripStatus),
  id: tripStatus as string,
}));

export const tripEventOptions: MOptionItem[] = Object.values(TripEventEventTypeEnum).map(
  (tripEvent: TripEventEventTypeEnum) => ({
    title: readableTripEvent(tripEvent),
    id: tripEvent as string,
  }),
);
export const getDriverFullName = (driver?: Driver): string => {
  if (!driver) return '—';

  return formatName(driver);
};
export const getDriverNumber = (driver?: Driver): string => {
  if (!driver) return '—';

  return formatPhone(driver.primaryPhoneNumber, { prefix: driver.primaryPhoneCountryCode });
};

export const getDefaultTripDriver = (tripNumber: number, position: number): TripDriverEdit => ({
  tripNumber,
  driverId: '',
  driver: undefined,
  finalSchedulePointId: '',
  position,
});

export const getDefaultTripVehicle = (tripNumber: number, position: number): TripVehicleEdit => ({
  tripNumber,
  vehicleId: '',
  vehicle: undefined,
  finalSchedulePointId: '',
  position,
});
