import type { RouteRecordRaw } from 'vue-router';

import { LayoutType, RouteName } from '@/shared/config/router';
import { useRouteLoadingStore } from '@/shared/config/stores/route-loading-store.ts';

export const route: RouteRecordRaw = {
  path: '/login',
  name: RouteName.LOGIN,
  component: () => {
    useRouteLoadingStore().setLoading(true);
    return import('./Login.vue');
  },

  meta: {
    layout: LayoutType.LOGIN,
  },
};
