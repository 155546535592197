import { Cities } from '@/shared/api/generated-api/logistic-points/Cities.ts';
import { env } from '@/shared/env';

import type { Api, ApiName } from './api.models';
import { Carriers } from './generated-api/carriers/Carriers.ts';
import { Drivers } from './generated-api/drivers/Drivers';
import { DriversLicenses } from './generated-api/drivers/DriversLicenses';
import { Models } from './generated-api/fleets/Models';
import { VehicleContracts } from './generated-api/fleets/VehicleContracts';
import { VehiclePassports } from './generated-api/fleets/VehiclePassports';
import { VehicleRegistrations } from './generated-api/fleets/VehicleRegistrations';
import { Vehicles } from './generated-api/fleets/Vehicles';
import { LogisticPoints } from './generated-api/logistic-points/LogisticPoints.ts';
import { Static } from './generated-api/transportation-orders/Static.ts';
import { TransportationOrders } from './generated-api/transportation-orders/TransportationOrders.ts';
import { Trips } from './generated-api/transportation-orders/Trips.ts';
import { addInterceptorsToApi } from './interceptors';
import { mockPorts } from './mocks/mock-ports';

const getApiUrl = (apiName: ApiName): string => {
  const urlMapByApiName: Record<ApiName, string> = {
    carriers: env.CARRIERS_API_GATEWAY_URL,
    drivers: env.DRIVERS_API_GATEWAY_URL,
    fleets: env.FLEETS_API_GATEWAY_URL,
    'logistic-points': env.LOGISTIC_POINTS_API_GATEWAY_URL,
    'transportation-orders': env.TRANSPORTATION_ORDERS_API_GATEWAY_URL_TEMP,
  };

  if (env.ENABLE_MOCKS_API) {
    return `http://localhost:${mockPorts[apiName]}`;
  }

  return urlMapByApiName[apiName];
};

const createApi = (): Api => {
  return {
    carriers: new Carriers({ baseURL: getApiUrl('carriers') }),
    drivers: new Drivers({ baseURL: getApiUrl('drivers') }),
    driversLicense: new DriversLicenses({ baseURL: getApiUrl('drivers') }),
    models: new Models({ baseURL: getApiUrl('fleets') }),
    vehicleContracts: new VehicleContracts({ baseURL: getApiUrl('fleets') }),
    vehiclePassports: new VehiclePassports({ baseURL: getApiUrl('fleets') }),
    vehicleRegistrations: new VehicleRegistrations({ baseURL: getApiUrl('fleets') }),
    vehicles: new Vehicles({ baseURL: getApiUrl('fleets') }),
    logisticPoints: new LogisticPoints({ baseURL: getApiUrl('logistic-points') }),
    cities: new Cities({ baseURL: getApiUrl('logistic-points') }),
    transportationOrders: new TransportationOrders({ baseURL: getApiUrl('transportation-orders') }),
    trips: new Trips({ baseURL: getApiUrl('transportation-orders') }),
    static: new Static({ baseURL: getApiUrl('transportation-orders') }),
  };
};

const api = createApi();
addInterceptorsToApi(api);

export { api };
