import type { RouteRecordRaw } from 'vue-router';

import { LayoutType, RouteName } from '@/shared/config/router';

export const route: RouteRecordRaw = {
  path: '/restore',
  name: RouteName.RESTORE,
  component: () => import('./ui/RestorePassword.vue'),
  meta: {
    layout: LayoutType.LOGIN,
  },
};
