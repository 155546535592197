/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  GenericError,
  GenericUnprocessableEntityError,
  GetModelsParams,
  HttpMethod,
  Model,
  Pagination,
} from './data-contracts';
import { HttpClient, RequestParams } from './http-client';

export const ModelsRoutesRegExp = {
  getModelById: {
    regExp: '^\\/api\\/v1\\/models\\/\\d+$',
    method: HttpMethod.GET,
  },
  updateModel: {
    regExp: '^\\/api\\/v1\\/models\\/\\d+$',
    method: HttpMethod.PUT,
  },
  getModels: {
    regExp: '^\\/api\\/v1\\/models$',
    method: HttpMethod.GET,
  },
  createModel: {
    regExp: '^\\/api\\/v1\\/models$',
    method: HttpMethod.POST,
  },
};

export class Models<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * @description Ручка возвращает данные автомобиля по его ID
   *
   * @tags Models
   * @name GetModelById
   * @summary Получение данных модели автомобиля
   * @request GET:/api/v1/models/{model_id}
   */
  getModelById = (modelId: string, params: RequestParams = {}) =>
    this.request<Model, GenericError | GenericUnprocessableEntityError>({
      path: `/api/v1/models/${modelId}`,
      method: 'GET',
      ...params,
    });
  /**
   * @description Ручка изменяет данные модели автомобиля по его ID
   *
   * @tags Models
   * @name UpdateModel
   * @summary Изменение данных модели автомобиля
   * @request PUT:/api/v1/models/{model_id}
   */
  updateModel = (modelId: string, data: Model, params: RequestParams = {}) =>
    this.request<Model, GenericError | GenericUnprocessableEntityError>({
      path: `/api/v1/models/${modelId}`,
      method: 'PUT',
      body: data,
      ...params,
    });
  /**
   * @description Получить список моделей автомобилей
   *
   * @tags Models
   * @name GetModels
   * @summary Получение списка моделей автомобилей
   * @request GET:/api/v1/models
   */
  getModels = (query: GetModelsParams, params: RequestParams = {}) =>
    this.request<
      {
        list: Model[];
        /** Постраничная навигация */
        pagination: Pagination;
      },
      GenericError | GenericUnprocessableEntityError
    >({
      path: `/api/v1/models`,
      method: 'GET',
      query: query,
      ...params,
    });
  /**
   * @description Ручка создает модель автомобиля
   *
   * @tags Models
   * @name CreateModel
   * @summary Создание модели автомобилей
   * @request POST:/api/v1/models
   */
  createModel = (data: Model, params: RequestParams = {}) =>
    this.request<Model, GenericError | GenericUnprocessableEntityError>({
      path: `/api/v1/models`,
      method: 'POST',
      body: data,
      ...params,
    });
}
