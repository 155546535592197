import type { RouteRecordRaw } from 'vue-router';

import { RouteName } from '@/shared/config/router';
import { useRouteLoadingStore } from '@/shared/config/stores/route-loading-store.ts';

export const route: RouteRecordRaw = {
  path: ':id',
  name: RouteName.TRANSPORTATION_ORDER,
  component: () => {
    useRouteLoadingStore().setLoading(true);
    return import('./ui/transportation-order.vue');
  },
  meta: {
    title({ route, readableRouteName: readableTitle }): string {
      return `${readableTitle} ${route.params.id}`;
    },
  },
};
