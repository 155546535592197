<template>
  <Suspense>
    <component :is="layoutComponent"></component>
  </Suspense>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { useRoute } from 'vue-router';

import { layoutComponents } from '@/app/router';
import { LayoutType } from '@/shared/config/router';
import { useAuthStore } from '@/shared/config/stores';

const route = useRoute();

const layoutComponent = computed(() => {
  const layoutName = (route.meta.layout ??
    (useAuthStore().accessToken ? LayoutType.DEFAULT : LayoutType.LOGIN)) as LayoutType;
  return layoutComponents[layoutName];
});
</script>

<style scoped lang="scss"></style>
