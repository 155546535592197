<template>
  <m-status
    class="order-status"
    :dot-color="transportationStatusDotColorMap[props.status]"
    type="filled"
  >
    {{ readableTransportationStatus(props.status) }}
  </m-status>
</template>

<script setup lang="ts">
import { MStatus } from '@mm-frontend/mithril-ui-kit';

import { TransportationOrderStatus } from '@/shared/api/generated-api/transportation-orders/data-contracts';

import { readableTransportationStatus, transportationStatusDotColorMap } from '../config';

const props = withDefaults(
  defineProps<{
    status?: TransportationOrderStatus;
  }>(),
  {
    status: TransportationOrderStatus.TRANSPORTATION_ORDER_STATUS_CANCELED,
  },
);
</script>
