/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { GenericError, GenericUnprocessableEntityError, HttpMethod, Passport } from './data-contracts';
import { HttpClient, RequestParams } from './http-client';

export const VehiclePassportsRoutesRegExp = {
  createVehiclePassport: {
    regExp: '^\\/api\\/v1\\/vehicles\\/\\d+\\/passports$',
    method: HttpMethod.POST,
  },
  updateVehiclePassport: {
    regExp: '^\\/api\\/v1\\/vehicles\\/\\d+$',
    method: HttpMethod.PUT,
  },
};

export class VehiclePassports<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * @description Ручка создает ПТС автомобиля по его ID
   *
   * @tags Vehicle passports
   * @name CreateVehiclePassport
   * @summary Добавление ПТС автомобиля
   * @request POST:/api/v1/vehicles/{vehicle_id}/passports
   */
  createVehiclePassport = (vehicleId: string, data: Passport, params: RequestParams = {}) =>
    this.request<Passport, GenericError | GenericUnprocessableEntityError>({
      path: `/api/v1/vehicles/${vehicleId}/passports`,
      method: 'POST',
      body: data,
      ...params,
    });
  /**
   * @description Ручка изменяет ПТС автомобиля по его ID и passport ID
   *
   * @tags Vehicle passports
   * @name UpdateVehiclePassport
   * @summary Изменение ПТС автомобиля
   * @request PUT:/api/v1/vehicles/{vehicle_id}/passports/{passport_id}
   */
  updateVehiclePassport = (vehicleId: string, passportId: string, data: Passport, params: RequestParams = {}) =>
    this.request<Passport, GenericError | GenericUnprocessableEntityError>({
      path: `/api/v1/vehicles/${vehicleId}/passports/${passportId}`,
      method: 'PUT',
      body: data,
      ...params,
    });
}
