export interface OptionItem {
  id: string;
  companyName?: string;
  inn?: string;
}

export interface OptionAttributes {
  inn?: string;
}

export type SelectWidth = 'normal' | 'narrow';

export type DropdownPosition = 'left' | 'right';

export interface CarrierContractOptionItem {
  id: string;
  name: string;
  vatPercentage: number;
  isNotVat: boolean;
  isVatInAmount: boolean;
}

export enum TripCarrierType {
  CARRIER_OWN = 'CARRIER_OWN',
  CARRIER_HIRED = 'CARRIER_HIRED',
}
