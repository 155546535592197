import type { OptionItem } from '@/entities/carrier';
import type { TripCarrierType } from '@/entities/carrier/types';
import type { LogisticPoint } from '@/shared/api/generated-api/logistic-points/data-contracts.ts';
import type {
  TransportationOrderStatus,
  TransportationType,
} from '@/shared/api/generated-api/transportation-orders/data-contracts.ts';

export type TransportationOrderLocalFilter = {
  planFirstDepartureAtDate?: string;
  planFirstDepartureAtFrom?: string;
  planFirstDepartureAtTo?: string;
  departureCity?: string;
  destinationCity?: string;
  transitCity?: string;
  departureLogisticPoint?: LogisticPoint;
  destinationLogisticPoint?: LogisticPoint;
  transitLogisticPoint?: LogisticPoint;
  status?: TransportationOrderStatus[];
  number?: number;
  isRoundTrip?: number;
  carrier?: OptionItem;
  type?: TransportationType[];
  isOwnCarrier?: TripCarrierType[];
  tripName?: string;
};

export enum AddTransportationOrderActions {
  ADD_INTRACITY_TRANSPORTATION = 'TRANSPORTATION_TYPE_INTRACITY_TRANSPORTATION',
  ADD_FROM_EXCEL = 'ADD_FROM_EXCEL',
  ADD_LONG_DISTANCE_TRANSPORTATION = 'TRANSPORTATION_TYPE_LONG_DISTANCE_TRANSPORTATION',
}
