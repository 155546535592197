import type { RouteRecordRaw } from 'vue-router';

import { RouteName } from '@/shared/config/router';
import { useRouteLoadingStore } from '@/shared/config/stores/route-loading-store.ts';

export const route: RouteRecordRaw = {
  path: 'add',
  name: RouteName.TRANSPORTATION_ORDER_ADD,
  component: () => {
    useRouteLoadingStore().setLoading(true);
    return import('./ui/transportation-order-add.vue');
  },
};
