/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  GenericError,
  GenericUnprocessableEntityError,
  GetVehiclesParams,
  HttpMethod,
  Pagination,
  Vehicle,
  VehicleAggregate,
} from './data-contracts';
import { HttpClient, RequestParams } from './http-client';

export const VehiclesRoutesRegExp = {
  getVehicleById: {
    regExp: '^\\/api\\/v1\\/vehicles\\/\\d+$',
    method: HttpMethod.GET,
  },
  updateVehicle: {
    regExp: '^\\/api\\/v1\\/vehicles\\/\\d+$',
    method: HttpMethod.PUT,
  },
  linkModelToVehicle: {
    regExp: '^\\/api\\/v1\\/vehicles\\/\\d+\\/link$',
    method: HttpMethod.PATCH,
  },
  getVehicles: {
    regExp: '^\\/api\\/v1\\/vehicles$',
    method: HttpMethod.GET,
  },
  createVehicle: {
    regExp: '^\\/api\\/v1\\/vehicles$',
    method: HttpMethod.POST,
  },
  createVehicleOwn: {
    regExp: '^\\/api\\/v1\\/vehicles\\/own$',
    method: HttpMethod.POST,
  },
  updateVehicleOwn: {
    regExp: '^\\/api\\/v1\\/vehicles\\/own\\/\\d+$',
    method: HttpMethod.PUT,
  },
  createVehicleOutsource: {
    regExp: '^\\/api\\/v1\\/vehicles\\/outsource$',
    method: HttpMethod.POST,
  },
  updateOutsourceVehicle: {
    regExp: '^\\/api\\/v1\\/vehicles\\/outsource\\/\\d+$',
    method: HttpMethod.PUT,
  },
  makeVehicleActive: {
    regExp: '^\\/api\\/v1\\/vehicles\\/\\d+\\/status\\/active$',
    method: HttpMethod.PATCH,
  },
  makeVehicleArchive: {
    regExp: '^\\/api\\/v1\\/vehicles\\/\\d+\\/status\\/archive$',
    method: HttpMethod.PATCH,
  },
};

export class Vehicles<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * @description Ручка возвращает данные автомобиля по его ID
   *
   * @tags Vehicles
   * @name GetVehicleById
   * @summary Получение данных автомобиля
   * @request GET:/api/v1/vehicles/{vehicle_id}
   */
  getVehicleById = (vehicleId: string, params: RequestParams = {}) =>
    this.request<VehicleAggregate, GenericError | GenericUnprocessableEntityError>({
      path: `/api/v1/vehicles/${vehicleId}`,
      method: 'GET',
      ...params,
    });
  /**
   * @description Ручка изменяет данные автомобиля по его ID
   *
   * @tags Vehicles
   * @name UpdateVehicle
   * @summary Изменение автомобиля
   * @request PUT:/api/v1/vehicles/{vehicle_id}
   */
  updateVehicle = (vehicleId: string, data: Vehicle, params: RequestParams = {}) =>
    this.request<VehicleAggregate, GenericError | GenericUnprocessableEntityError>({
      path: `/api/v1/vehicles/${vehicleId}`,
      method: 'PUT',
      body: data,
      ...params,
    });
  /**
   * @description Ручка привязывает id модели к данным автомобиля по его ID
   *
   * @tags Vehicles
   * @name LinkModelToVehicle
   * @summary Привязка модели автомобиля к данным транспорта
   * @request PATCH:/api/v1/vehicles/{vehicle_id}/model/{model_id}/link
   * @deprecated
   */
  linkModelToVehicle = (vehicleId: string, modelId: string, params: RequestParams = {}) =>
    this.request<VehicleAggregate, GenericError | GenericUnprocessableEntityError>({
      path: `/api/v1/vehicles/${vehicleId}/model/${modelId}/link`,
      method: 'PATCH',
      ...params,
    });
  /**
   * @description Получить список автомобилей
   *
   * @tags Vehicles
   * @name GetVehicles
   * @summary Получение списка автомобилей
   * @request GET:/api/v1/vehicles
   */
  getVehicles = (query: GetVehiclesParams, params: RequestParams = {}) =>
    this.request<
      {
        list: Vehicle[];
        /** Постраничная навигация */
        pagination: Pagination;
      },
      GenericError | GenericUnprocessableEntityError
    >({
      path: `/api/v1/vehicles`,
      method: 'GET',
      query: query,
      ...params,
    });
  /**
   * @description Создание автомобиля
   *
   * @tags Vehicles
   * @name CreateVehicle
   * @summary Создание автомобиля
   * @request POST:/api/v1/vehicles
   */
  createVehicle = (data: Vehicle, params: RequestParams = {}) =>
    this.request<VehicleAggregate, GenericError | GenericUnprocessableEntityError>({
      path: `/api/v1/vehicles`,
      method: 'POST',
      body: data,
      ...params,
    });
  /**
   * @description Создание собственного автомобиля
   *
   * @tags Vehicles
   * @name CreateVehicleOwn
   * @summary Создание собственного автомобиля
   * @request POST:/api/v1/vehicles/own
   * @deprecated
   */
  createVehicleOwn = (data: Vehicle, params: RequestParams = {}) =>
    this.request<VehicleAggregate, GenericError | GenericUnprocessableEntityError>({
      path: `/api/v1/vehicles/own`,
      method: 'POST',
      body: data,
      ...params,
    });
  /**
   * @description Ручка изменяет данные собственного автомобиля по его ID
   *
   * @tags Vehicles
   * @name UpdateVehicleOwn
   * @summary Изменение собственного автомобиля
   * @request PUT:/api/v1/vehicles/own/{vehicle_id}
   * @deprecated
   */
  updateVehicleOwn = (vehicleId: string, data: Vehicle, params: RequestParams = {}) =>
    this.request<VehicleAggregate, GenericError | GenericUnprocessableEntityError>({
      path: `/api/v1/vehicles/own/${vehicleId}`,
      method: 'PUT',
      body: data,
      ...params,
    });
  /**
   * @description Создание привлеченного автомобиля
   *
   * @tags Vehicles
   * @name CreateVehicleOutsource
   * @summary Создание привлеченного автомобиля
   * @request POST:/api/v1/vehicles/outsource
   * @deprecated
   */
  createVehicleOutsource = (data: Vehicle, params: RequestParams = {}) =>
    this.request<VehicleAggregate, GenericError | GenericUnprocessableEntityError>({
      path: `/api/v1/vehicles/outsource`,
      method: 'POST',
      body: data,
      ...params,
    });
  /**
   * @description Ручка изменяет данные привлеченного автомобиля по его ID
   *
   * @tags Vehicles
   * @name UpdateOutsourceVehicle
   * @summary Изменение привлеченного автомобиля
   * @request PUT:/api/v1/vehicles/outsource/{vehicle_id}
   * @deprecated
   */
  updateOutsourceVehicle = (vehicleId: string, data: Vehicle, params: RequestParams = {}) =>
    this.request<VehicleAggregate, GenericError | GenericUnprocessableEntityError>({
      path: `/api/v1/vehicles/outsource/${vehicleId}`,
      method: 'PUT',
      body: data,
      ...params,
    });
  /**
   * @description Ручка обновляет статус автомобиля по его ID в ACTIVE
   *
   * @tags Vehicles
   * @name MakeVehicleActive
   * @summary Обновление статуса автомобиля на активный
   * @request PATCH:/api/v1/vehicles/{vehicle_id}/status/active
   */
  makeVehicleActive = (vehicleId: string, params: RequestParams = {}) =>
    this.request<VehicleAggregate, GenericError | GenericUnprocessableEntityError>({
      path: `/api/v1/vehicles/${vehicleId}/status/active`,
      method: 'PATCH',
      ...params,
    });
  /**
   * @description Ручка обновляет статус автомобиля по его ID в ARCHIVE
   *
   * @tags Vehicles
   * @name MakeVehicleArchive
   * @summary Обновление статуса автомобиля на архивный
   * @request PATCH:/api/v1/vehicles/{vehicle_id}/status/archive
   */
  makeVehicleArchive = (vehicleId: string, params: RequestParams = {}) =>
    this.request<VehicleAggregate, GenericError | GenericUnprocessableEntityError>({
      path: `/api/v1/vehicles/${vehicleId}/status/archive`,
      method: 'PATCH',
      ...params,
    });
}
