/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { GenericError, GenericUnprocessableEntityError, HttpMethod, Registration } from './data-contracts';
import { HttpClient, RequestParams } from './http-client';

export const VehicleRegistrationsRoutesRegExp = {
  createVehicleRegistration: {
    regExp: '^\\/api\\/v1\\/vehicles\\/\\d+\\/registrations$',
    method: HttpMethod.POST,
  },
  updateVehicleRegistration: {
    regExp: '^\\/api\\/v1\\/vehicles\\/\\d+$',
    method: HttpMethod.PUT,
  },
};

export class VehicleRegistrations<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * @description Ручка создает СТС автомобиля по его ID
   *
   * @tags Vehicle registrations
   * @name CreateVehicleRegistration
   * @summary Добавление СТС автомобиля
   * @request POST:/api/v1/vehicles/{vehicle_id}/registrations
   */
  createVehicleRegistration = (vehicleId: string, data: Registration, params: RequestParams = {}) =>
    this.request<Registration, GenericError | GenericUnprocessableEntityError>({
      path: `/api/v1/vehicles/${vehicleId}/registrations`,
      method: 'POST',
      body: data,
      ...params,
    });
  /**
   * @description Ручка изменяет СТС автомобиля по его ID и ID регистрации
   *
   * @tags Vehicle registrations
   * @name UpdateVehicleRegistration
   * @summary Изменение СТС автомобиля
   * @request PUT:/api/v1/vehicles/{vehicle_id}/registrations/{registration_id}
   */
  updateVehicleRegistration = (
    vehicleId: string,
    registrationId: string,
    data: Registration,
    params: RequestParams = {},
  ) =>
    this.request<Registration, GenericError | GenericUnprocessableEntityError>({
      path: `/api/v1/vehicles/${vehicleId}/registrations/${registrationId}`,
      method: 'PUT',
      body: data,
      ...params,
    });
}
