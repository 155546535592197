import { fetchRefreshToken } from '@/shared/api/interceptors/refresh-token.ts';

import type { ResponseInterceptor } from './types.ts';

export const apiErrorsInterceptor: ResponseInterceptor = {
  onRejected: async (error, axiosInstance) => {
    if (error.response && error.response.status === 401) {
      return await fetchRefreshToken(error, axiosInstance);
    }

    throw error;
  },
};
