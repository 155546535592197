/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { Contract, GenericError, GenericUnprocessableEntityError, HttpMethod } from './data-contracts';
import { HttpClient, RequestParams } from './http-client';

export const VehicleContractsRoutesRegExp = {
  createContract: {
    regExp: '^\\/api\\/v1\\/contracts$',
    method: HttpMethod.POST,
  },
  updateContract: {
    regExp: '^\\/api\\/v1\\/contracts\\/\\d+$',
    method: HttpMethod.PUT,
  },
};

export class VehicleContracts<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * @description Ручка создает договор автомобиля
   *
   * @tags Vehicle contracts
   * @name CreateContract
   * @summary Добавление договора автомобиля
   * @request POST:/api/v1/contracts
   */
  createContract = (data: Contract, params: RequestParams = {}) =>
    this.request<Contract, GenericError | GenericUnprocessableEntityError>({
      path: `/api/v1/contracts`,
      method: 'POST',
      body: data,
      ...params,
    });
  /**
   * @description Ручка изменяет договора автомобиля по его ID
   *
   * @tags Vehicle contracts
   * @name UpdateContract
   * @summary Изменение договора автомобиля
   * @request PUT:/api/v1/contracts/{contract_id}
   */
  updateContract = (contractId: string, data: Contract, params: RequestParams = {}) =>
    this.request<Contract, GenericError | GenericUnprocessableEntityError>({
      path: `/api/v1/contracts/${contractId}`,
      method: 'PUT',
      body: data,
      ...params,
    });
}
