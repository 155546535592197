import { between, helpers, minLength, required } from '@vuelidate/validators';

import type { Carrier } from '@/shared/api/generated-api/fleets/data-contracts.ts';
import { createMinLengthMessage, createRangeValidationMessage, REQUIRED_MESSAGE } from '@/shared/config/validation';

export const validationRules: {
  carriers: object;
  registrationCountry: object;
  regNumber: object;
  manufacturedYear?: object;
} = {
  carriers: {
    required: helpers.withMessage(REQUIRED_MESSAGE, (value: Carrier[]) => value.length > 0),
  },
  registrationCountry: {
    required: helpers.withMessage(REQUIRED_MESSAGE, required),
  },
  regNumber: {
    required: helpers.withMessage(REQUIRED_MESSAGE, required),
    minLength: helpers.withMessage(createMinLengthMessage(8), minLength(8)),
  },
  manufacturedYear: {
    required: helpers.withMessage(REQUIRED_MESSAGE, required),
    range: helpers.withMessage(createRangeValidationMessage(1900, 2100), between(1900, 2100)),
  },
};
