import type { MIconNames } from '@mm-frontend/mithril-ui-kit';
import { helpers, required, requiredIf } from '@vuelidate/validators';
import type { Ref } from 'vue';

import { BodyType, VehicleType } from '@/shared/api/generated-api/fleets/data-contracts';
import type { ModelVehicleTypeEnum } from '@/shared/api/generated-api/transportation-orders/data-contracts';
import {
  createMinValueMessage,
  createOneFieldLessThanSecondMessage,
  REQUIRED_MESSAGE,
} from '@/shared/config/validation';
import { readableEnum } from '@/shared/lib/utils';

import type { FormModel } from './types';

export const defaultModel: FormModel = {
  brand: '',
  model: '',
  bodyType: undefined,
  vehicleMass: undefined,
  permissibleMaximumMass: undefined,
  vehicleType: undefined,
  loadCapacity: 0,
  bodyWidth: undefined,
  bodyHeight: undefined,
  bodyDepth: undefined,
  passengersCapacity: 0,
  cargoSpace: 0,
};

export const readableVehicleType = readableEnum<VehicleType | ModelVehicleTypeEnum>({
  [VehicleType.VEHICLE_TYPE_CARGO]: 'Грузовой фургон',
  [VehicleType.VEHICLE_TYPE_TRAILER]: 'Прицеп',
  [VehicleType.VEHICLE_TYPE_CAR]: 'Легковой',
  [VehicleType.VEHICLE_TYPE_PASSENGER]: 'Пассажирский',
  [VehicleType.VEHICLE_TYPE_SPECIAL]: 'Спецтехника',
  [VehicleType.VEHICLE_TYPE_TRACTOR]: 'Тягач',
});

export const readableBodyType = readableEnum<BodyType>({
  [BodyType.BODY_TYPE_TENT]: 'Тент',
  [BodyType.BODY_TYPE_ISOMETRIC]: 'Изометрический',
  [BodyType.BODY_TYPE_OPEN]: 'Открытый',
  [BodyType.BODY_TYPE_METAL]: 'Цельнометаллический',
  [BodyType.BODY_TYPE_REFRIGERATOR]: 'Рефрижератор',
});

export const iconFromCarType = readableEnum<VehicleType | ModelVehicleTypeEnum, MIconNames>({
  [VehicleType.VEHICLE_TYPE_CARGO]: 'vehicle-truck-profile',
  [VehicleType.VEHICLE_TYPE_TRAILER]: 'transport_trailer',
  [VehicleType.VEHICLE_TYPE_CAR]: 'transport_car',
  [VehicleType.VEHICLE_TYPE_PASSENGER]: 'transport_bus',
  [VehicleType.VEHICLE_TYPE_SPECIAL]: 'transport_special',
  [VehicleType.VEHICLE_TYPE_TRACTOR]: 'transport_truck',
});

export const createModelValidationRules = (
  model: Ref<
    | {
        vehicleType?: VehicleType;
        permissibleMaximumMass?: number;
      }
    | undefined
  >,
): {
  brand: object;
  model: object;
  vehicleType: object;
  bodyType?: object;
  vehicleMass?: object;
  permissibleMaximumMass?: object;
  bodyDepth?: object;
  bodyWidth?: object;
  bodyHeight?: object;
  passengersCapacity: object;
} => {
  const requiredValidationByType = (vehicleTypes: VehicleType[]) =>
    helpers.withMessage(
      REQUIRED_MESSAGE,
      requiredIf(() => !!model.value?.vehicleType && vehicleTypes.includes(model.value.vehicleType)),
    );
  const numberFieldValidationByType = (vehicleTypes: VehicleType[]) => ({
    requiredIf: requiredValidationByType(vehicleTypes),
    minValue: helpers.withMessage(createMinValueMessage(1), (value?: string | number) => {
      return value !== undefined &&
        String(value).length &&
        model.value?.vehicleType &&
        vehicleTypes.includes(model.value.vehicleType)
        ? +value >= 1
        : true;
    }),
  });

  const trailerAndCargoVehicleTypes = [VehicleType.VEHICLE_TYPE_TRAILER, VehicleType.VEHICLE_TYPE_CARGO];

  const trailerCarAndCargoVehicleTypes = [...trailerAndCargoVehicleTypes, VehicleType.VEHICLE_TYPE_CAR];
  const trailerCarCargoAndTractorVehicleTypes = [...trailerCarAndCargoVehicleTypes, VehicleType.VEHICLE_TYPE_TRACTOR];

  return {
    brand: {
      required: helpers.withMessage(REQUIRED_MESSAGE, required),
    },
    model: {
      required: helpers.withMessage(REQUIRED_MESSAGE, required),
    },
    vehicleType: {
      required: helpers.withMessage(REQUIRED_MESSAGE, required),
    },
    bodyType: {
      requiredIf: requiredValidationByType(trailerAndCargoVehicleTypes),
    },
    vehicleMass: {
      ...numberFieldValidationByType(trailerCarCargoAndTractorVehicleTypes),
      lessThanPermissibleMaximumMass: helpers.withMessage(
        createOneFieldLessThanSecondMessage('Разрешенная max масса'),
        (value?: number) => {
          return !!(
            (!value && !model.value?.permissibleMaximumMass) ||
            (!value && model.value?.permissibleMaximumMass) ||
            (value && !model.value?.permissibleMaximumMass) ||
            (value && model.value?.permissibleMaximumMass && value < model.value.permissibleMaximumMass)
          );
        },
      ),
    },
    permissibleMaximumMass: numberFieldValidationByType(trailerCarCargoAndTractorVehicleTypes),
    bodyDepth: numberFieldValidationByType(trailerCarAndCargoVehicleTypes),
    bodyHeight: numberFieldValidationByType(trailerCarAndCargoVehicleTypes),
    bodyWidth: numberFieldValidationByType(trailerCarAndCargoVehicleTypes),
    passengersCapacity: numberFieldValidationByType([VehicleType.VEHICLE_TYPE_PASSENGER, VehicleType.VEHICLE_TYPE_CAR]),
  };
};
