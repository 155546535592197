type Coords = {
  latitude: number;
  longitude: number;
};

/**
 * Форматирует дистанцию в метрах в километры
 *
 * @param {number} m - Дистанция в метрах
 * @return {string} Расстояние в километрах и единицей измерения «км».
 */
export const formatMetrToKilometr = (m: number): string => {
  return `${Math.ceil(m / 1000)} км`;
};

/**
 * Преобразует градусы в радианы.
 *
 * @param {number} x - Угол в градусах, который необходимо преобразовать.
 * @return {number} Угол в радианах.
 */
export const rad = (x: number): number => {
  return (x * Math.PI) / 180;
};

/**
 * Вычисляет расстояние между двумя координатами по формуле Хаверсинуса.
 *
 * @param {Coords} p1 - Координаты первой точки
 * @param {Coords} p2 - Координаты второй точки
 * @return {number} Расстояние между двумя точками в метрах
 */
export const getDistance = (p1: Coords, p2: Coords): number => {
  const R = 6_378_137; // Earth’s mean radius in meter
  const dLat = rad(p2.latitude - p1.latitude);
  const dLong = rad(p2.longitude - p1.longitude);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(rad(p1.latitude)) * Math.cos(rad(p2.latitude)) * Math.sin(dLong / 2) * Math.sin(dLong / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  return R * c; // returns the distance in meter
};
