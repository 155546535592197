import { formatUserName } from '@mm-frontend/mithril-ui-kit';

interface IUser {
  firstName: string;
  surname: string;
  middleName?: string;
}

export const formatName = (user: IUser, format: string = 'L F P'): string =>
  formatUserName({ firstName: user.firstName, lastName: user.surname, patronymic: user.middleName }, format);
