/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

/** Error response. */
export interface GenericError {
  /** Код ошибки */
  code: ErrorCode;
  /**
   * Заголовок
   * @example Ошибка списания
   */
  title: string;
  /**
   * Подробное описание ошибки
   * @example Error message
   */
  detail: string;
  /**
   * Текст ошибки для команды разработки
   * @example Error message
   */
  debugMessage: string;
  /**
   * Уникальный ID запроса
   * @example e19c227c-55f3-48a9-9511-ce973bdcd1e2
   */
  traceId: string;
}

/** Возврат ошибки запроса */
export interface GenericUnprocessableEntityError {
  /** Код ошибки */
  code: ErrorCode;
  /**
   * Заголовок
   * @example Ошибка создания автомобиля
   */
  title: string;
  /**
   * Подробное описание ошибки
   * @example При попытке создать автомобиль, обнаружены ошибки. Попробуйте исправить их и попробовать снова.
   */
  detail: string;
  /**
   * Текст ошибки для команды разработки
   * @example Field: vehicle.model, message: не указана модель автомобиля
   */
  debugMessage: string;
  /**
   * Уникальный ID запроса
   * @example e19c227c-55f3-48a9-9511-ce973bdcd1e2
   */
  traceId: string;
  /** Список ошибок валидации */
  validationErrors: ValidationErrors;
}

/** Код ошибки */
export enum ErrorCode {
  Value500100 = 500100,
  Value400100 = 400100,
  Value400101 = 400101,
  Value403100 = 403100,
  Value404100 = 404100,
  Value422100 = 422100,
  Value409100 = 409100,
}

/** Список ошибок валидации */
export type ValidationErrors = ValidationError[];

/** Ошибка валидации */
export interface ValidationError {
  /**
   * Наименование поля
   * @example ID
   */
  fieldName: string;
  /**
   * Сообщение с ошибкой валидации
   * @example Укажите ID в формате UUID
   */
  message: string;
}

export interface VehicleAggregate {
  /**
   * ID автомобиля
   * @format uuid
   * @example b32baf8b-f939-4e27-9bab-f13223399eed
   */
  id?: string;
  /** Тип взаимодействия (собственный или привлеченный) */
  interactionType: InteractionType;
  /** Страна регистрации */
  registrationCountry: RegistrationCountry;
  /** Статус */
  status: VehicleStatus;
  /**
   * Регистрационный номер
   * @example А123АА777
   */
  regNumber: string;
  /**
   * Год выпуска
   * @min 1900
   * @max 2100
   * @example 2020
   */
  manufacturedYear: number;
  /**
   * Брендирование
   * @example true
   */
  isBranding?: boolean;
  /** Тип обслуживания ТС */
  serviceType?: ServiceType;
  /**
   * Гарантия действует до
   * @format date
   * @example 2020-03-20
   */
  warrantyValidAt?: string;
  /** Тип владения ТС */
  ownershipType: OwnershipType;
  /** Модель транспорта */
  model?: Model;
  /** Договор */
  contract?: Contract;
  passports: Passport[];
  registrations: Registration[];
  carriers: Carrier[];
}

export interface Vehicle {
  /**
   * ID автомобиля
   * @format uuid
   * @example b32baf8b-f939-4e27-9bab-f13223399eed
   */
  id?: string;
  /** Тип взаимодействия (собственный или привлеченный) */
  interactionType: InteractionType;
  /** Страна регистрации */
  registrationCountry: RegistrationCountry;
  /** Статус */
  status: VehicleStatus;
  /**
   * Регистрационный номер
   * @example А123АА777
   */
  regNumber: string;
  /**
   * Год выпуска
   * @min 1900
   * @max 2100
   * @example 2020
   */
  manufacturedYear: number;
  /**
   * Брендирование
   * @example true
   */
  isBranding?: boolean;
  /** Тип обслуживания ТС */
  serviceType?: ServiceType;
  /**
   * Гарантия действует до
   * @format date
   * @example 2020-03-20
   */
  warrantyValidAt?: string;
  /** Тип владения ТС */
  ownershipType: OwnershipType;
  /**
   * ID модели
   * @format uuid
   * @example b32baf8b-f939-4e27-9bab-f13223399eed
   */
  modelId?: string;
  /**
   * ID договора
   * @format uuid
   * @example b32baf8b-f939-4e27-9bab-f13223399eed
   */
  contractId?: string;
  /** Модель транспорта */
  model?: Model;
  carriers: Carrier[];
}

/**
 * Страна регистрации
 * @example "REGISTRATION_COUNTRY_RUSSIA"
 */
export enum RegistrationCountry {
  REGISTRATION_COUNTRY_RUSSIA = 'REGISTRATION_COUNTRY_RUSSIA',
}

/**
 * Тип взаимодействия (собственный или привлеченный)
 * @example "INTERACTION_TYPE_OWN"
 */
export enum InteractionType {
  INTERACTION_TYPE_OWN = 'INTERACTION_TYPE_OWN',
  INTERACTION_TYPE_OUTSOURCE = 'INTERACTION_TYPE_OUTSOURCE',
}

/**
 * Статус
 * @example "VEHICLE_STATUS_ACTIVE"
 */
export enum VehicleStatus {
  VEHICLE_STATUS_ACTIVE = 'VEHICLE_STATUS_ACTIVE',
  VEHICLE_STATUS_REPAIR = 'VEHICLE_STATUS_REPAIR',
  VEHICLE_STATUS_ARCHIVE = 'VEHICLE_STATUS_ARCHIVE',
  VEHICLE_STATUS_DRAFT = 'VEHICLE_STATUS_DRAFT',
}

/**
 * Тип владения ТС
 * @example "OWNERSHIP_TYPE_OWNERSHIP"
 */
export enum OwnershipType {
  OWNERSHIP_TYPE_OWNERSHIP = 'OWNERSHIP_TYPE_OWNERSHIP',
  OWNERSHIP_TYPE_JOINT_OWNERSHIP_OF_SPOUSES = 'OWNERSHIP_TYPE_JOINT_OWNERSHIP_OF_SPOUSES',
  OWNERSHIP_TYPE_IN_LEASING = 'OWNERSHIP_TYPE_IN_LEASING',
  OWNERSHIP_TYPE_RENTED = 'OWNERSHIP_TYPE_RENTED',
  OWNERSHIP_TYPE_GRATUITOUS_POSSESSION = 'OWNERSHIP_TYPE_GRATUITOUS_POSSESSION',
}

/**
 * Тип обслуживания ТС
 * @example "SERVICE_TYPE_WARRANTY"
 */
export enum ServiceType {
  SERVICE_TYPE_WARRANTY = 'SERVICE_TYPE_WARRANTY',
  SERVICE_TYPE_SELF_SERVICE = 'SERVICE_TYPE_SELF_SERVICE',
  SERVICE_TYPE_CONTRACT = 'SERVICE_TYPE_CONTRACT',
}

/** Перевозчик */
export interface Carrier {
  /**
   * ID перевозчика
   * @format uuid
   * @example b32baf8b-f939-4e27-9bab-f13223399eed
   */
  id: string;
  /**
   * Наименование перевозчика
   * @example ООО "Автосервис"
   */
  companyName?: string;
  /**
   * ИНН перевозчика
   * @example 1234567890
   */
  inn?: string;
}

/** Модель транспорта */
export interface Model {
  /**
   * ID модели транспорта
   * @format uuid
   * @example b32baf8b-f939-4e27-9bab-f13223399eed
   */
  id?: string;
  /**
   * Бренд автомобиля
   * @example Mercedes
   */
  brand: string;
  /**
   * Модель автомобиля
   * @example E350
   */
  model: string;
  /** Тип автомобиля */
  vehicleType: VehicleType;
  /**
   * Вместимость груза в граммах
   * @min 0
   * @example 10011
   */
  loadCapacity: number;
  /**
   * Вместимость груза в мм3
   * @min 0
   * @example 100
   */
  cargoSpace?: number;
  /**
   * Масса автомобиля в граммах
   * @min 0
   * @example 10000
   */
  vehicleMass?: number;
  /**
   * Максимальная допустимая масса в граммах
   * @min 0
   * @example 20000
   */
  permissibleMaximumMass?: number;
  /** Тип кузова */
  bodyType?: BodyType;
  /** Метод загрузки */
  loadMethod?: LoadMethod;
  /**
   * Высота кузова в миллиметрах
   * @min 0
   * @example 200
   */
  bodyHeight?: number;
  /**
   * Глубина кузова в миллиметрах
   * @min 0
   * @example 200
   */
  bodyDepth?: number;
  /**
   * Ширина кузова в миллиметрах
   * @min 0
   * @example 200
   */
  bodyWidth?: number;
  /**
   * Пассажиры сидячие, человек
   * @min 0
   * @example 5
   */
  passengersCapacity: number;
}

/**
 * Тип автомобиля
 * @example "VEHICLE_TYPE_CARGO"
 */
export enum VehicleType {
  VEHICLE_TYPE_CARGO = 'VEHICLE_TYPE_CARGO',
  VEHICLE_TYPE_TRAILER = 'VEHICLE_TYPE_TRAILER',
  VEHICLE_TYPE_CAR = 'VEHICLE_TYPE_CAR',
  VEHICLE_TYPE_PASSENGER = 'VEHICLE_TYPE_PASSENGER',
  VEHICLE_TYPE_SPECIAL = 'VEHICLE_TYPE_SPECIAL',
  VEHICLE_TYPE_TRACTOR = 'VEHICLE_TYPE_TRACTOR',
}

/**
 * Тип кузова
 * @example "BODY_TYPE_TENT"
 */
export enum BodyType {
  BODY_TYPE_TENT = 'BODY_TYPE_TENT',
  BODY_TYPE_ISOMETRIC = 'BODY_TYPE_ISOMETRIC',
  BODY_TYPE_OPEN = 'BODY_TYPE_OPEN',
  BODY_TYPE_METAL = 'BODY_TYPE_METAL',
  BODY_TYPE_REFRIGERATOR = 'BODY_TYPE_REFRIGERATOR',
}

/**
 * Метод загрузки
 * @example "LOAD_METHOD_LOW"
 */
export enum LoadMethod {
  LOAD_METHOD_LOW = 'LOAD_METHOD_LOW',
  LOAD_METHOD_HIGH = 'LOAD_METHOD_HIGH',
}

/** Договор */
export interface Contract {
  /**
   * ID договора
   * @format uuid
   * @example b32baf8b-f939-4e27-9bab-f13223399eed
   */
  id?: string;
  /** Тип договора */
  contractType: ContractType;
  /**
   * Номер договора
   * @example 12345
   */
  number: string;
  /**
   * Дата подписания договора
   * @format date
   * @example 2020-03-20
   */
  signedAt: string;
  /**
   * ИНН контрагента
   * @example 1234567890
   */
  contractorInn: string;
}

/**
 * Тип договора
 * @example "CONTRACT_TYPE_RENTAL"
 */
export enum ContractType {
  CONTRACT_TYPE_RENTAL = 'CONTRACT_TYPE_RENTAL',
  CONTRACT_TYPE_LEASING = 'CONTRACT_TYPE_LEASING',
  CONTRACT_TYPE_GRATUITOUS_USE = 'CONTRACT_TYPE_GRATUITOUS_USE',
}

/** СТС */
export interface Registration {
  /**
   * ID СТС
   * @format uuid
   * @example b32baf8b-f939-4e27-9bab-f13223399eed
   */
  id?: string;
  /**
   * ID транспорта, которому принадлежит СТС
   * @format uuid
   * @example b32baf8b-f939-4e27-9bab-f13223399eed
   */
  vehicleId: string;
  /**
   * Серия СТС
   * @example 12345
   */
  series: string;
  /**
   * Номер СТС
   * @example 678901
   */
  number: string;
  /**
   * Дата выдачи СТС
   * @format date
   * @example 2020-03-20
   */
  issuedAt: string;
}

/** ПТС */
export interface Passport {
  /**
   * ID ПТС
   * @format uuid
   * @example a1f3b7a6-8d18-4f02-a5c3-f5eac9d70b3c
   */
  id?: string;
  /**
   * ID транспорта, которому принадлежит ПТС
   * @format uuid
   * @example b2e4c8d2-9e27-5e14-b6d4-c8e4a2f9c1a7
   */
  vehicleId: string;
  /**
   * Наличие электронного ПТС
   * @example true
   */
  isVirtual: boolean;
  /**
   * Серия ПТС
   * @example XYZ12
   */
  series?: string;
  /**
   * Номер ПТС
   * @example 9876543210
   */
  number: string;
  /**
   * Дата выдачи ПТС
   * @format date
   * @example 2022-03-20
   */
  issuedAt: string;
}

/** Страница */
export interface Page {
  /**
   * Количество элементов на странице
   * @min 0
   * @max 500
   * @example 10
   */
  size: number;
  /**
   * Номер страницы
   * @min 1
   * @example 4
   */
  number: number;
}

/** Постраничная навигация */
export interface Pagination {
  /** Страница */
  self: Page;
  /** Страница */
  first: Page;
  /** Страница */
  prev?: Page;
  /** Страница */
  next?: Page;
  /** Страница */
  last: Page;
  /**
   * Общее количество элементов
   * @min 0
   * @example 100
   */
  size: number;
}

export interface GetVehiclesParams {
  /**
   * Марка автомобиля
   * @example Audi
   */
  brand?: string;
  /**
   * Модель автомобиля
   * @example A6
   */
  model?: string;
  /** Тип автомобиля */
  vehicleTypes?: VehicleType[];
  /**
   * минимальный объем кузова в мм3
   * @min 0
   * @example 1000
   */
  cargoSpaceMin?: number;
  /**
   * максимальный объем кузова в мм3
   * @min 0
   * @example 2000
   */
  cargoSpaceMax?: number;
  /** Статус транспорта */
  statuses?: VehicleStatus[];
  /**
   * Год выпуска
   * @min 1990
   * @max 2100
   * @example 2020
   */
  manufacturedYear?: number;
  /**
   * регистрационный номер
   * @example a123ab55
   */
  regNumber?: string;
  /**
   * id перевозчика
   * @format uuid
   * @example b32baf8b-f939-4e27-9bab-f13223399eed
   */
  carrierId?: string;
  /** Страница */
  page?: Page;
}

export interface GetModelsParams {
  /**
   * Марка автомобиля
   * @example Audi
   */
  brand?: string;
  /**
   * Модель автомобиля
   * @example A6
   */
  model?: string;
  /** Тип кузова */
  bodyTypes?: BodyType[];
  /** Тип автомобиля */
  vehicleTypes?: VehicleType[];
  /** Страница */
  page?: Page;
}

export enum HttpMethod {
  GET = 'get',
  POST = 'post',
  PUT = 'put',
  PATCH = 'patch',
  DELETE = 'delete',
}
