import dateFormat from 'dateformat';

const BACKEND_DATE_FORMAT = 'yyyy-mm-dd';

export const convertDateToBackend = (
  date?: string | number | Date,
  options: { format?: string; emptyText?: string } = {},
): string => {
  if (!date) {
    return options.emptyText || '';
  }

  return dateFormat(date, options.format || BACKEND_DATE_FORMAT);
};

export const createDateFromString = (stringDate?: string): Date | null => (stringDate ? new Date(stringDate) : null);

export const replaceCommaInDate = (date: string): string => {
  return date.replace(',', ' -');
};

export const removeUTC = (date: string | undefined): string | undefined => {
  return date ? date.replace('Z', '') : undefined;
};

export const removeTimezoneOffset = (dateString: string | Date): Date => {
  const date = dateString instanceof Date ? dateString : new Date(dateString);
  return new Date(date.getTime() - date.getTimezoneOffset() * 60_000);
};

export const getDiffDays = (date1: Date, date2: Date): number => {
  if (date1.getDate() === date2.getDate()) return 0;
  const diffTime = date2.getTime() - date1.getTime();
  return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
};
