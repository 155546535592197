import { RouteName } from './types';

export const getReadableRouteName = (routeName: RouteName): string => {
  const readableRouteMap: Record<RouteName, string> = {
    [RouteName.DRIVERS]: 'Водители',
    [RouteName.VEHICLES]: 'Парк ТС',
    [RouteName.MODELS]: 'Модели ТС',
    [RouteName.CARRIERS]: 'Перевозчики',
    [RouteName.LOGISTIC_POINTS]: 'Лог. пункты',
    [RouteName.TRANSPORTATION_ORDERS]: 'Планирование рейсов',
    [RouteName.TRANSPORTATION_ORDER]: 'Заказ на рейс №',
    [RouteName.TRANSPORTATION_ORDER_ADD]: 'Создание заказа',
    [RouteName.HOME]: 'Главная',
    [RouteName.MONITORING]: 'Мониторинг',
    [RouteName.LOGIN]: 'Авторизация',
    [RouteName.RESTORE]: 'Восстановление пароля',
    [RouteName.NOT_FOUND]: 'Страница не найдена',
  };

  return readableRouteMap[routeName];
};
