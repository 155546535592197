/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

/** Error response. */
export interface GenericError {
  /** Код ошибки */
  code: ErrorCode;
  /**
   * Заголовок
   * @example Ошибка списания
   */
  title: string;
  /**
   * Подробное описание ошибки
   * @example Error message
   */
  detail: string;
  /**
   * Текст ошибки для команды разработки
   * @example Error message
   */
  debugMessage: string;
  /**
   * Уникальный ID запроса
   * @example e19c227c-55f3-48a9-9511-ce973bdcd1e2
   */
  traceId: string;
}

/** Возврат ошибки запроса */
export interface GenericBadRequestError {
  /** Код ошибки */
  code: ErrorCode;
  /**
   * Заголовок
   * @example Ошибка создания водителя
   */
  title: string;
  /**
   * Подробное описание ошибки
   * @example При попытке создать водителя, обнаружены ошибки. Попробуйте исправить их и попробовать снова.
   */
  detail: string;
  /**
   * Текст ошибки для команды разработки
   * @example Field: name, message: не указано ФИО
   */
  debugMessage: string;
  /**
   * Уникальный ID запроса
   * @example e19c227c-55f3-48a9-9511-ce973bdcd1e2
   */
  traceId: string;
  /** Список ошибок валидации */
  validationErrors: ValidationErrors;
}

/** Код ошибки */
export enum ErrorCode {
  Value500100 = 500100,
  Value400100 = 400100,
  Value403100 = 403100,
  Value404100 = 404100,
  Value409100 = 409100,
  Value422100 = 422100,
}

/** Список ошибок валидации */
export type ValidationErrors = ValidationError[];

/** Ошибка валидации */
export interface ValidationError {
  /**
   * Наименование поля
   * @example ID
   */
  fieldName: string;
  /**
   * Сообщение с ошибкой валидации
   * @example Укажите ID в формате UUID
   */
  message: string;
}

/** Полные данные по водителю */
export interface DriverAggregate {
  /**
   * ID водителя
   * @format uuid
   * @example b32baf8b-f939-4e27-9bab-f13223399eed
   */
  id: string;
  /**
   * Табельный номер водителя
   * @example 1234
   */
  personalNumber?: string;
  /**
   * Имя водителя
   * @example Иван
   */
  firstname: string;
  /**
   * Фамилия водителя
   * @example Иванов
   */
  surname: string;
  /**
   * Отчество водителя
   * @example Иванович
   */
  middleName?: string;
  /**
   * Показывает, существует ли отчество
   * @example true
   */
  isMiddleNameExist: boolean;
  /**
   * Номер СНИЛС
   * @example 12345678912
   */
  snilsNumber: string;
  /** Тип контракта */
  contractType: DriversContractType;
  /**
   * Дата рождения водителя
   * @format date
   * @example 1988-03-20
   */
  bornAt?: string;
  /**
   * Дата получения водительского удостоверения
   * @format date
   * @example 2018-03-20
   */
  firstDriverLicenseIssuedAt?: string;
  /**
   * Дата найма водителя
   * @format date
   * @example 2019-03-20
   */
  hiredAt?: string;
  /**
   * Код страны
   * @example +7
   */
  primaryPhoneCountryCode: string;
  /**
   * Номер телефона
   * @example 9998887766
   */
  primaryPhoneNumber: string;
  /**
   * Код страны
   * @example +7
   */
  secondaryPhoneCountryCode?: string;
  /**
   * Дополнительный номер телефона
   * @example 9998887755
   */
  secondaryPhoneNumber?: string;
  /**
   * Адрес водителя
   * @example г Москва, ул Пушкина, дом Коло-на
   */
  address: string;
  driverLicenses: DriverLicense[];
  medicalExaminations: MedicalExamination[];
  medicalCertificates: MedicalCertificate[];
  tachographCards: TachographCard[];
  carriers: Carrier[];
}

/** Категория водительского удостоверения */
export interface DriverLicenseCategory {
  /**
   * ID категории
   * @format uuid
   * @example b32baf8b-f939-4e27-9bab-f13223399eed
   */
  id?: string;
  /**
   * ID водительского удостоверения
   * @format uuid
   * @example b32baf8b-f939-4e27-9bab-f13223399eed
   */
  driverLicenseId?: string;
  /** Код категории */
  code: DriversLicenseCategoryCode;
}

/** Водительское удостоверение */
export interface DriverLicense {
  /**
   * ID водительского удостоверения
   * @format uuid
   * @example b32baf8b-f939-4e27-9bab-f13223399eed
   */
  id?: string;
  /**
   * ID водителя
   * @format uuid
   * @example b32baf8b-f939-4e27-9bab-f13223399eed
   */
  driverId?: string;
  /**
   * Серия водительского удостоверения
   * @example 1234
   */
  series: string;
  /**
   * Номер водительского удостоверения
   * @example 567890
   */
  number: string;
  /** Статус водительского удостоверения */
  status: DriversLicenseStatus;
  /**
   * Дополнительный флаг, является ли ВУ активным
   * @example true
   */
  isActive: boolean;
  /**
   * Дополнительный флаг, является ли ВУ просроченным
   * @example true
   */
  isExpired: boolean;
  /**
   * Дата выдачи водительского удостоверения
   * @format date
   * @example 2009-03-20
   */
  issuedAt: string;
  /**
   * Дата истечения срока действия водительского удостоверения
   * @format date
   * @example 2031-03-20
   */
  expiresAt: string;
  categories: DriverLicenseCategory[];
}

/** Медицинская справка */
export interface MedicalCertificate {
  /**
   * Уникальный идентификатор медицинской справки
   * @format uuid
   * @example b32baf8b-f939-4e27-9bab-f13223399eed
   */
  id?: string;
  /**
   * ID водителя, связанный с медицинской справкой
   * @format uuid
   * @example b32baf8b-f939-4e27-9bab-f13223399eed
   */
  driverId: string;
  /**
   * Дата выдачи медицинской справки
   * @format date
   * @example 2024-03-20
   */
  issuedAt: string;
  /**
   * Дата истечения срока действия медицинской справки
   * @format date
   * @example 2025-03-20
   */
  expiresAt: string;
}

/** Карта тахографа */
export interface TachographCard {
  /**
   * Уникальный идентификатор карты тахографа
   * @format uuid
   * @example b32baf8b-f939-4e27-9bab-f13223399eed
   */
  id?: string;
  /**
   * ID водителя, связанный с тахографом
   * @format uuid
   * @example b32baf8b-f939-4e27-9bab-f13223399eed
   */
  driverId?: string;
  /**
   * Номер карты тахографа
   * @example RUD0000112233440
   */
  number: string;
  /**
   * Дата начала действия карты тахографа
   * @format date
   * @example 2020-03-20
   */
  issuedAt: string;
  /**
   * Дата истечения срока действия карты тахографа
   * @format date
   * @example 2030-03-20
   */
  expiresAt: string;
}

/** Медицинское освидетельствование */
export interface MedicalExamination {
  /**
   * Уникальный идентификатор медицинского осмотра
   * @format uuid
   * @example b32baf8b-f939-4e27-9bab-f13223399eed
   */
  id?: string;
  /**
   * ID водителя, связанный с медицинским осмотром
   * @format uuid
   * @example b32baf8b-f939-4e27-9bab-f13223399eed
   */
  driverId?: string;
  /**
   * Тип медицинского осмотра
   * @example Предрейсовый
   */
  checkType: string;
  /**
   * Дата проведения медицинского осмотра
   * @format date
   * @example 2023-03-20
   */
  issuedAt: string;
  /**
   * Дата истечения срока действия медицинского осмотра
   * @format date
   * @example 2025-03-20
   */
  expiresAt: string;
  /**
   * Результат медицинского осмотра
   * @example Допущен
   */
  result: string;
}

/** Краткая информация о водителях для списка */
export interface Driver {
  /**
   * ID водителя
   * @format uuid
   * @example b32baf8b-f939-4e27-9bab-f13223399eed
   */
  id: string;
  /**
   * Имя водителя
   * @example Иван
   */
  firstName: string;
  /**
   * Отчество водителя
   * @example Иванович
   */
  middleName?: string;
  /**
   * Показывает, существует ли отчество
   * @example true
   */
  isMiddleNameExist: boolean;
  /**
   * Фамилия водителя
   * @example Иванов
   */
  surname: string;
  /**
   * Код страны
   * @example +7
   */
  primaryPhoneCountryCode: string;
  /**
   * Номер телефона водителя
   * @example 9998887766
   */
  primaryPhoneNumber: string;
  /**
   * Код страны
   * @example +7
   */
  secondaryPhoneCountryCode?: string;
  /**
   * Номер телефона водителя
   * @example 9998887755
   */
  secondaryPhoneNumber?: string;
  /**
   * СНИЛС
   * @example 12345678912
   */
  snilsNumber: string;
  carriers: Carrier[];
}

export interface CreateStaffDriver {
  /**
   * Табельный номер водителя
   * @example 1234
   */
  personalNumber: string;
  /**
   * Имя водителя
   * @example Иван
   */
  firstname: string;
  /**
   * Фамилия водителя
   * @example Иванов
   */
  surname: string;
  /**
   * Отчество водителя
   * @example Иванович
   */
  middleName?: string;
  /**
   * Показывает, существует ли отчество
   * @example true
   */
  isMiddleNameExist: boolean;
  /**
   * Номер СНИЛС
   * @example 12345678912
   */
  snilsNumber: string;
  /**
   * Дата рождения водителя
   * @format date
   * @example 1988-03-20
   */
  bornAt: string;
  /**
   * Дата получения водительского удостоверения
   * @format date
   * @example 2009-03-20
   */
  firstDriverLicenseIssuedAt: string;
  /**
   * Дата найма водителя
   * @format date
   * @example 2021-03-20
   */
  hiredAt: string;
  /**
   * Код страны
   * @example +7
   */
  primaryPhoneCountryCode: string;
  /**
   * Номер телефона
   * @example 9998887766
   */
  primaryPhoneNumber: string;
  /**
   * Код страны
   * @example +7
   */
  secondaryPhoneCountryCode?: string;
  /**
   * Дополнительный номер телефона
   * @example 9998887755
   */
  secondaryPhoneNumber?: string;
  /**
   * Адрес водителя
   * @example г Москва, ул Пушкина, дом Коло-на
   */
  address: string;
  carriers: Carrier[];
  driverLicenses: DriverLicense[];
}

export interface CreateOutsourceDriver {
  /**
   * Имя водителя
   * @example Иван
   */
  firstname: string;
  /**
   * Фамилия водителя
   * @example Иванов
   */
  surname: string;
  /**
   * Отчество водителя
   * @example Иванович
   */
  middleName?: string;
  /**
   * Показывает, существует ли отчество
   * @example true
   */
  isMiddleNameExist: boolean;
  /**
   * Номер СНИЛС
   * @example 12345678912
   */
  snilsNumber: string;
  /**
   * Код страны
   * @example +7
   */
  primaryPhoneCountryCode: string;
  /**
   * Номер телефона
   * @example 9998887766
   */
  primaryPhoneNumber: string;
  /**
   * Код страны
   * @example +7
   */
  secondaryPhoneCountryCode?: string;
  /**
   * Дополнительный номер телефона
   * @example 9998887755
   */
  secondaryPhoneNumber?: string;
  carriers: Carrier[];
  driverLicenses: DriverLicense[];
}

export interface UpdateStaffDriver {
  /**
   * Табельный номер водителя
   * @example 1234
   */
  personalNumber: string;
  /**
   * Имя водителя
   * @example Иван
   */
  firstname: string;
  /**
   * Фамилия водителя
   * @example Иванов
   */
  surname: string;
  /**
   * Отчество водителя
   * @example Иванович
   */
  middleName?: string;
  /**
   * Показывает, существует ли отчество
   * @example true
   */
  isMiddleNameExist: boolean;
  /**
   * Номер СНИЛС
   * @format ^[0-9]{3}-[0-9]{3}-[0-9]{3} [0-9]{2}$
   * @example 123-456-789 12
   */
  snilsNumber: string;
  /**
   * Дата рождения водителя
   * @format date
   * @example 1988-03-20
   */
  bornAt: string;
  /**
   * Дата получения водительского удостоверения
   * @format date
   * @example 2009-03-20
   */
  firstDriverLicenseIssuedAt: string;
  /**
   * Дата найма водителя
   * @format date
   * @example 2022-03-20
   */
  hiredAt: string;
  /**
   * Код страны
   * @example +7
   */
  primaryPhoneCountryCode: string;
  /**
   * Номер телефона
   * @example 9998887766
   */
  primaryPhoneNumber: string;
  /**
   * Код страны
   * @example +7
   */
  secondaryPhoneCountryCode?: string;
  /**
   * Дополнительный номер телефона
   * @example 9998887755
   */
  secondaryPhoneNumber?: string;
  /**
   * Адрес водителя
   * @example г Москва, ул Пушкина, дом Коло-на
   */
  address: string;
  carriers: Carrier[];
}

export interface UpdateOutsourceDriver {
  /**
   * Имя водителя
   * @example Иван
   */
  firstname: string;
  /**
   * Фамилия водителя
   * @example Иванов
   */
  surname: string;
  /**
   * Отчество водителя
   * @example Иванович
   */
  middleName?: string;
  /**
   * Показывает, существует ли отчество
   * @example true
   */
  isMiddleNameExist: boolean;
  /**
   * Номер СНИЛС
   * @format ^[0-9]{3}-[0-9]{3}-[0-9]{3} [0-9]{2}$
   * @example 123-456-789 12
   */
  snilsNumber: string;
  /**
   * Код страны
   * @example +7
   */
  primaryPhoneCountryCode: string;
  /**
   * Номер телефона
   * @example 9998887766
   */
  primaryPhoneNumber: string;
  /**
   * Код страны
   * @example +7
   */
  secondaryPhoneCountryCode?: string;
  /**
   * Дополнительный номер телефона
   * @example 9998887755
   */
  secondaryPhoneNumber?: string;
  carriers: Carrier[];
}

/**
 * Тип контракта
 * @example "DRIVER_CONTRACT_TYPE_STAFF"
 */
export enum DriversContractType {
  DRIVER_CONTRACT_TYPE_STAFF = 'DRIVER_CONTRACT_TYPE_STAFF',
  DRIVER_CONTRACT_TYPE_OUTSOURCE = 'DRIVER_CONTRACT_TYPE_OUTSOURCE',
}

/**
 * Статус водительского удостоверения
 * @example "DRIVER_LICENCE_ACTIVE"
 */
export enum DriversLicenseStatus {
  DRIVER_LICENCE_ACTIVE = 'DRIVER_LICENCE_ACTIVE',
  DRIVER_LICENCE_INACTIVE = 'DRIVER_LICENCE_INACTIVE',
  DRIVER_LICENCE_EXPIRED = 'DRIVER_LICENCE_EXPIRED',
}

/**
 * Код категории
 * @maxLength 50
 * @example "DRIVER_LICENCE_CATEGORY_CODE_A"
 */
export enum DriversLicenseCategoryCode {
  DRIVER_LICENCE_CATEGORY_CODE_A = 'DRIVER_LICENCE_CATEGORY_CODE_A',
  DRIVER_LICENCE_CATEGORY_CODE_A1 = 'DRIVER_LICENCE_CATEGORY_CODE_A1',
  DRIVER_LICENCE_CATEGORY_CODE_B = 'DRIVER_LICENCE_CATEGORY_CODE_B',
  DRIVER_LICENCE_CATEGORY_CODE_B1 = 'DRIVER_LICENCE_CATEGORY_CODE_B1',
  DRIVER_LICENCE_CATEGORY_CODE_BE = 'DRIVER_LICENCE_CATEGORY_CODE_BE',
  DRIVER_LICENCE_CATEGORY_CODE_C = 'DRIVER_LICENCE_CATEGORY_CODE_C',
  DRIVER_LICENCE_CATEGORY_CODE_C1 = 'DRIVER_LICENCE_CATEGORY_CODE_C1',
  DRIVER_LICENCE_CATEGORY_CODE_CE = 'DRIVER_LICENCE_CATEGORY_CODE_CE',
  DRIVER_LICENCE_CATEGORY_CODE_C1E = 'DRIVER_LICENCE_CATEGORY_CODE_C1E',
  DRIVER_LICENCE_CATEGORY_CODE_D = 'DRIVER_LICENCE_CATEGORY_CODE_D',
  DRIVER_LICENCE_CATEGORY_CODE_D1 = 'DRIVER_LICENCE_CATEGORY_CODE_D1',
  DRIVER_LICENCE_CATEGORY_CODE_DE = 'DRIVER_LICENCE_CATEGORY_CODE_DE',
  DRIVER_LICENCE_CATEGORY_CODE_D1E = 'DRIVER_LICENCE_CATEGORY_CODE_D1E',
  DRIVER_LICENCE_CATEGORY_CODE_M = 'DRIVER_LICENCE_CATEGORY_CODE_M',
  DRIVER_LICENCE_CATEGORY_CODE_TM = 'DRIVER_LICENCE_CATEGORY_CODE_TM',
  DRIVER_LICENCE_CATEGORY_CODE_TB = 'DRIVER_LICENCE_CATEGORY_CODE_TB',
}

/** Перевозчик */
export interface Carrier {
  /**
   * ID перевозчика
   * @format uuid
   * @example b32baf8b-f939-4e27-9bab-f13223399eed
   */
  id: string;
  /**
   * Наименование перевозчика
   * @example ООО "Автосервис"
   */
  companyName?: string;
  /**
   * ИНН перевозчика
   * @example 1234567890
   */
  inn?: string;
}

/** Страница */
export interface Page {
  /**
   * Количество элементов на странице
   * @min 0
   * @max 500
   * @example 10
   */
  size: number;
  /**
   * Номер страницы
   * @min 1
   * @example 4
   */
  number: number;
}

/** Постраничная навигация */
export interface Pagination {
  /** Страница */
  self: Page;
  /** Страница */
  first: Page;
  /** Страница */
  prev?: Page;
  /** Страница */
  next?: Page;
  /** Страница */
  last: Page;
  /**
   * Общее количество элементов
   * @min 1
   * @max 500
   * @example 100
   */
  size: number;
}

export interface GetDriversParams {
  /**
   * ФИО водителя
   * @example Александр Иванович Петренко
   */
  driverFio?: string;
  /**
   * Телефон водителя
   * @example +7 999 999 99 99
   */
  driverPhone?: string;
  /** СНИЛС */
  driverSnils?: string;
  /**
   * ID перевозчика
   * @format uuid
   * @example b32baf8b-f939-4e27-9bab-f13223399eed
   */
  carrierId?: string;
  /** Страница */
  page?: Page;
}

export type DeleteDriverLicensesByIdData = any;

export enum HttpMethod {
  GET = 'get',
  POST = 'post',
  PUT = 'put',
  PATCH = 'patch',
  DELETE = 'delete',
}
