import type { MTypographyColor } from '@mm-frontend/mithril-ui-kit';

import type { Trip } from '@/shared/api/generated-api/transportation-orders/data-contracts';
import { TripStatus } from '@/shared/api/generated-api/transportation-orders/data-contracts';
import { secondsToReadableTime } from '@/shared/lib/utils';

export const convertTZ = (date?: Date | string, tzString?: string): Date | undefined => {
  if (!date) return;
  return new Date((typeof date === 'string' ? new Date(date) : date).toLocaleString('en-US', { timeZone: tzString }));
};

export const colorFromTimeLeft = (time?: number): MTypographyColor => {
  if (!time) return 'primary';

  return time < 0 ? 'negative' : 'primary';
};

export const getTimeLeft = (time?: number): string => {
  if (!time) return '—';

  const isSign: boolean = time < 0;
  const timeLeftInSeconds = Math.abs(time);

  if (timeLeftInSeconds <= 60) return '~1м';
  return `${isSign ? '-' : ''}${secondsToReadableTime(timeLeftInSeconds, { showSeconds: false })}`;
};

export const isCompleted = (trip: Trip): boolean => trip.status === TripStatus.TRIP_STATUS_COMPLETED;

export const checkIsFirstPoint = (trip: Trip): boolean => {
  if (!trip.transportationOrder) return false;
  return trip.transportationOrder.schedule.firstSchedulePoint.id === trip.expectedEvent?.schedulePoint.id;
};
