<template>
  <!-- @todo избавиться, когда обновим ui-kit -->
  <date-picker
    v-model="componentModelValue"
    :min-date="minDate"
    :max-date="maxDate"
    :masks="{ input: [dateMask] }"
    @update:model-value="emit('update:modelValue', $event)"
  >
    <template #default="{ togglePopover, inputValue, inputEvents }">
      <m-input
        placeholder="__.__.__"
        size="small"
        :mask="inputMask"
        :clearable="false"
        :model-value="inputValue"
        :errors="props.errors"
        v-on="inputEvents"
      >
        <template #after>
          <slot name="input-after">
            <m-icon
              :style="{ cursor: 'pointer' }"
              name="calendar"
              variant="regular"
              @click="togglePopover"
            />
          </slot>
        </template>
      </m-input>
    </template>
  </date-picker>
</template>

<script lang="ts" setup>
import { MIcon, MInput } from '@mm-frontend/mithril-ui-kit';
import { DatePicker } from 'v-calendar';
import { computed, ref, watch } from 'vue';

const props = withDefaults(
  defineProps<{
    modelValue?: Date;
    minDate?: Date;
    maxDate?: Date;
    errors?: string[];
  }>(),
  {
    modelValue: undefined,
    minDate: undefined,
    maxDate: undefined,
    errors: () => [],
  },
);

const emit = defineEmits<{
  'update:modelValue': [value: Date];
}>();

const componentModelValue = ref(props.modelValue);

const dateMask = 'DD.MM.YY';

const inputMask = computed<string | undefined>(() => {
  return dateMask.replaceAll(/[ADHLMSYZdhms]/g, '#');
});

watch(
  () => props.modelValue,
  (newValue) => {
    componentModelValue.value = newValue;
  },
);
</script>
